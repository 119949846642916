<div [id]="secId" class="py-30 py-md-60 position-relative">
	<div class="container">
		<div class="mt-20" *ngIf="tokenError; else buildSec">
			<div class="alert alert-danger text-center text-danger" translate>{{tokenError}}</div>
		</div>
		<ng-template #buildSec>
			<ng-container *ngIf="invData; else noData">
				<ng-container *ngIf="!invData?.scheduled; else invNotSent">
					<ng-container *ngIf="invData?.pay_status !== 'void'; else invVoid">
						<ng-container *ngIf="invData?.pay_status !== 'paid'; else paidInv">
							<ng-container *ngIf="!invServ.hideSidebar">
								<ng-container *ngTemplateOutlet="printTemp"></ng-container>
							</ng-container>
							<form [formGroup]="invForm">
								<div class="row" [ngClass]="{'d-flex justify-content-center': invServ.hideSidebar}">
									<div class="col-lg-8 col-12 mb-20">
										<ng-container *ngIf="invServ.hideSidebar">
											<ng-container *ngTemplateOutlet="printTemp"></ng-container>
										</ng-container>
										<div class="inv-container w-100">
											<div [id]="invSec?.id" class="card bk-inv-card-p-0 bk-inv-card-bg bk-media bk-border overflow-hidden" [style.background-image]="(invSec?.media?.[0])?'url(' + invSec?.media[0] + ')':''">
												<div [id]="invSec?.media_id" *ngIf="invSec?.media && (invSec?.media).length>0" class="tjs-opacity--bg z-0-imp"></div>
												<div class="bk-inv-bg bk-inv-padding z-1">
													<!-- Logo/Title/Email/Address -->
													<div class="row mb-sm-50 mb-30" *ngIf="cusumData?.logo?.visibility == 'yes' || cusumData?.title?.visibility == 'yes' || cusumData?.email?.visibility == 'yes' || cusumData?.address?.visibility == 'yes'">
														<div class="col-md-6 col-12">
															<div class="d-flex align-items-center">
																<div *ngIf="cusumData?.logo && cusumData?.logo?.visibility == 'yes'" [id]="invSec?.logo_id" class="me-15 flex-shrink-0">
																	<bk-navigate essentialClass="bk-logo text-capitalize px-0" [secSett]="invSec?.logo?.link" [innerHTML]="invLogo" [secId]="invSec?.logo?.media_id"></bk-navigate>
																</div>
																<div class="flex-grow-1" *ngIf="cusumData?.title?.visibility == 'yes' || cusumData?.email?.visibility == 'yes'">
																	<div *ngIf="cusumData?.title?.visibility == 'yes'" [id]="invSec?.title_id" [innerHTML]="cusumData?.title?.text | translate | safeHtml" class="word-break fs-14"></div>
																	<div *ngIf="cusumData?.email?.visibility == 'yes'" [id]="invSec?.email_id" [innerHTML]="cusumData?.email?.text | translate | safeHtml" class="word-break fs-14"></div>
																</div>
															</div>
														</div>
														<div *ngIf="cusumData?.address?.visibility == 'yes'" class="col-md-6 col-8 text-md-end">
															<address [id]="invSec?.address_id ? invSec?.address_id : 'address'" [innerHTML]="cusumData?.address?.text | translate | safeHtml"></address>
														</div>
													</div>
													<!-- Billing Info -->
													<div class="card mb-sm-50 mb-30 bk-border" *ngIf="invSec?.billing_info && (cusumData?.inv_num?.visibility == 'yes' || cusumData?.issue_date?.visibility == 'yes' || cusumData?.due_date?.visibility == 'yes' || cusumData?.billed_to?.visibility == 'yes')" [id]="invSec?.billing_info_id">
														<div class="card-body p-0 row">
															<div class="col-md-6 col-12 mb-sm-0 mb-15">
																<div *ngIf="cusumData?.inv_num?.visibility == 'yes'" [id]="invSec?.billing_info?.inv_num_id">
																	<span class="d-block bk-label-clr bk-label-fw" [innerHTML]="cusumData?.inv_num?.text | translate | safeHtml"></span>
																	<span class="bk-inv-desc-clr" [innerHTML]="invData?.inv_num | translate | safeHtml"></span>
																</div>
																<div *ngIf="cusumData?.issue_date?.visibility == 'yes'" [id]="invSec?.billing_info?.issue_date_id">
																	<span [innerHTML]="cusumData?.issue_date?.text | translate | safeHtml" class="me-5 bk-label-clr bk-label-fw"></span>
																	<bk-date-display class="d-inline-block bk-inv-desc-clr" [date]="invData?.issue_date" [isStr]="true" [isLocal]="true"></bk-date-display>
																</div>
																<div *ngIf="cusumData?.due_date?.visibility == 'yes'" [id]="invSec?.billing_info?.due_date_id">
																	<span class="bk-label-clr bk-label-fw me-5" [innerHTML]="cusumData?.due_date?.text | translate | safeHtml"></span>
																	<bk-date-display class="d-inline-block bk-inv-desc-clr" [date]="invData?.due_date" [isStr]="true" [isLocal]="true"></bk-date-display>
																</div>
															</div>
															<div class="col-md-6 col-12 text-md-end" *ngIf="cusumData?.billed_to?.visibility == 'yes'">
																<div [id]="invSec?.billing_info?.billed_to_id">
																	<span class="bk-label-clr bk-label-fw" [innerHTML]="cusumData?.billed_to?.text | translate | safeHtml"></span>
																</div>
																<div class="bk-inv-desc-clr" *ngIf="invData?.customer?.company_name">{{invData?.customer?.company_name}}</div>
																<div class="bk-inv-desc-clr">{{invData?.customer?.first_name}} {{invData?.customer?.last_name}}</div>
																<div class="bk-inv-desc-clr" *ngIf="invData?.address?.address">
																	<div *ngIf="invData?.address?.apt">Apt - {{invData?.address?.apt}}</div>
																	<div>
																		<span *ngIf="invData?.address?.short_address; else fullAddr">{{invData?.address?.short_address}}</span>
																		<ng-template #fullAddr>
																			<span>{{invData?.address?.address}}</span>
																		</ng-template>
																	</div>
																	<div *ngIf="invData?.address?.short_address && (invData?.address?.city || invData?.address?.zipcode || invData?.address?.state || invData?.address?.country)">
																		<span>{{custAddr}}</span>
																	</div>
																</div>
																<div *ngIf="invData?.customer?.email_id">
																	<a class="bk-inv-desc-clr" href="mailto:{{invData?.customer?.email_id}}">{{invData?.customer?.email_id}}</a>
																</div>
																<div *ngIf="invData?.customer?.phone_number">
																	<bk-phone-number customClass="bk-inv-desc-clr" [phone]="invData?.customer?.phone_number" [hideIcon]="true"></bk-phone-number>
																</div>
															</div>
														</div>
													</div>
													<!-- Custom table -->
													<div *ngIf="invData?.inv_type == 'custom' else bkngTable" class="mb-30" [class.mb-sm-50]="invData?.custom_fields">
														<div class="mb-20 tjs-table-header--fixed tjs-scrollbar rounded-3 border" *ngFor="let table of invData?.tables;trackBy: utilServ.trackByFnIndex" [id]="invSec?.table_id">
															<table class="table bk-table tjs-table tjs-table--hover tjs-table--responsive tjs-table--border__x tjs-table-layout--fixed" [id]="table">
																<thead *ngIf="(invData?.columns?.[table]?.length || 0) > 0">
																	<tr>
																		<th *ngFor="let column of invData?.columns?.[table];trackBy: utilServ.trackByFnIndex" [style.width]="column?.width+'px'">{{column?.name | translate}}</th>
																	</tr>
																</thead>
																<tbody *ngIf="((invData?.line_items?.[table])?.length || 0) > 0 && ((invData?.columns?.[table])?.length || 0) > 0">
																	<tr *ngFor="let row of invData?.line_items?.[table];let i = index;trackBy: utilServ.trackById;">
																		<td [attr.data-label]="column?.name" *ngFor="let column of invData?.columns?.[table];trackBy: utilServ.trackByFnIndex;">
																			<span *ngIf="column?.type == 'priceable'; else textBlock">
																				<bk-amount-display [amount]="row?.[column?.id]?.value" [isZero]="true"></bk-amount-display>
																			</span>
																			<ng-template #textBlock><span class="d-block text-justify word-break">{{row?.[column?.id]?.value | translate}}</span></ng-template>
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
													<ng-template #bkngTable>
														<bk-inv-from-bkng-table [invForm]="invForm" [invSec]="invSec" [invData]="invData" (bkngsTipAmt)="fetchBkngInvAmt($event)" (summBkngFieldsChange)="onSummBkngFieldsChange($event)"></bk-inv-from-bkng-table>
													</ng-template>
													<!-- Summary -->
													<bk-inv-custom-fields [invSec]="invSec" [invData]="invData" [bkngAmtData]="invServ.bkngAmtData"></bk-inv-custom-fields>
													<!-- Note -->
													<div class="mb-sm-50 mb-30" *ngIf="invData?.note">
														<label class="form-label" [id]="invSec?.note_id" *ngIf="invSec?.note" [innerHTML]="invSec?.note | safeHtml"></label>
														<div class="text-justify" [innerHTML]="invData?.note | translate | safeHtml"></div>
													</div>
													<bk-phantom-field [control]="invForm.controls['inv_form_timestamp']"></bk-phantom-field>
													<!-- Pay Invoice button -->
													<bk-navigate *ngIf="cusumData?.button?.visibility == 'yes' && invData?.pay_status !== 'paid'" [designId]="invSec?.button?.design_id" [secId]="invSec?.button_id" [innerHTML]="cusumData?.button?.text | translate" essentialClass="w-100" (callback)="payInv()"></bk-navigate>
													<!-- Payment logs -->
													<bk-inv-payment-logs *ngIf="invSec?.payment_logs" [invId]="invId" [invSec]="invSec" [cusumData]="cusumData" [invData]="invData"></bk-inv-payment-logs>
													<!-- Branding -->
													<bk-branding type="img" customWrapClass="d-flex justify-content-center align-items-center mt-30"></bk-branding>
												</div>
											</div>
										</div>
									</div>
									<div class="col-lg-4 col-12" *ngIf="invData?.pay_status !== 'paid' && !invServ.hideSidebar">
										<bk-inv-sidebar [invSidebar]="section?.sidebar" [invData]="invData" [invForm]="invForm" [bkngAmtData]="invServ.bkngAmtData" (reCalBkngAmt)="fetchBkngInvAmt()"></bk-inv-sidebar>
									</div>
								</div>
							</form>
						</ng-container>
						<ng-template #paidInv>
							<div class="row d-flex justify-content-center">
								<div class="col-lg-8 col-12 mb-20">
									<ng-container *ngTemplateOutlet="printTemp"></ng-container>
									<div class="inv-container w-100">
										<img loading='lazy' [src]="paidInvImgUrl" class="img-responsive" width="auto" height="auto"/>
									</div>
								</div>
							</div>
						</ng-template>
					</ng-container>
					<!-- Void payment -->
					<ng-template #invVoid>
						<ng-container *ngTemplateOutlet="errorTemp; context:{msg:initServ.appStr.errorMsg?.invVoid}"></ng-container>
					</ng-template>
				</ng-container>
				<ng-template #invNotSent>
					<ng-container *ngTemplateOutlet="errorTemp; context:{msg:initServ.appStr.errorMsg?.invScheduled}"></ng-container>
				</ng-template>
			</ng-container>
			<ng-template #noData>
				<ng-container *ngTemplateOutlet="errorTemp; context:{msg:initServ.appStr.errorMsg?.noReceivedInv}"></ng-container>
			</ng-template>
		</ng-template>
	</div>
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>
<ng-template #printTemp>
	<div class="mb-20 d-flex justify-content-end align-items-center tjs-pointer" *ngIf="section?.download_pdf" [id]="section?.download_pdf?.id">
		<div class="tjs-tooltip-wrap">
			<button type="button" class="btn btn-link p-0" (click)="downloadInv()">
				<img *ngIf="section?.download_pdf?.urls && (section?.download_pdf?.urls).length > 0 && section?.download_pdf?.urls[0];else elsePrint" [src]="section?.download_pdf?.urls[0]" alt="logo" width="30" height="20">
				<ng-template #elsePrint>
					<span class="fs-20 text-start fw-bold text-capitalize">PRINT</span>
				</ng-template>
			</button>
			<div class="tjs-tooltip tjs-tooltip--left bottom-0-imp">{{initServ.appStr.tTip.downloadPdf | translate}}</div>
		</div>
	</div>
</ng-template>
<ng-template #errorTemp let-msg="msg">
	<div class="tjs-blog__not-found text-center tjs-py-140">
		<ng-container *ngIf="apiHit; else loader">
			<div class="mb-30"><i class="tjsicon-information text-danger"></i></div>
			<h3 class="fw-bold" translate>{{msg | translate}}</h3>
		</ng-container>
		<ng-template #loader>
			<div class="mb-30"><i class="tjsicon-refresh-1 text-gainsboro"></i></div>
		</ng-template>
	</div>
</ng-template>
