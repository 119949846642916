import { Component, OnInit, ViewEncapsulation, Self, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
// External library
import { ToastrService } from 'ngx-toastr';
// Services
import { NgOnDestroy, InitServ, ApiServ, LoaderServ, UtilServ, CacheService } from '../../../Services';
// Constants
import { TEXT_REG_EXP, EMAIL_REG_EXP } from '../../../Constants';

@Component({
	selector: 'bk-add-other-contact-popup',
	templateUrl: './AddOtherContactPopup.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class AddOtherContactPopupComponent implements OnInit {
	// Variables
	contactForm: FormGroup;
	uid: any;
	contactId: any;
	phoneMask: any = this.initServ.selectedMask; // App phone number masking
	loaderId: string = 'add-contact-loader';
	contact: any;
	// Editor configuration
	tinymceConfig: any = {
		height: 250,
		menubar: false,
		plugins: [
			'advlist autolink lists link image charmap',
			'anchor searchreplace visualblocks',
			'insertdatetime',
			'wordcount'
		],
		toolbar: ['bold italic underline strikethrough link | fontsizeselect | alignleft aligncenter alignright alignjustify | numlist bullist | forecolor removeformat'],
		toolbar_mode: 'wrap',
		a11y_advanced_options: true,
		skin: 'oxide',
		content_css: 'default',
		contextmenu: false,
		forced_root_block: "",
		directionality: (this.initServ.selectedLang && this.initServ.selectedLang.rtl) ? "rtl" : 'ltr',
		deprecation_warnings: false
	}
	// Section fields
	secId: string = '';
	slug: string = 'add_contact';
	section: any = { title: null, update_title: null, form: null, save_btn: null, cancel_btn: null };

	constructor(public dialogRef: MatDialogRef<AddOtherContactPopupComponent>, private cDRef: ChangeDetectorRef, private toastr: ToastrService, public initServ: InitServ, private frmBldr: FormBuilder, @Self() private destroy: NgOnDestroy, private apiServ: ApiServ, private loader: LoaderServ, public utilServ: UtilServ, private cacheServ: CacheService) {
		// Form build
		this.contactForm = this.frmBldr.group({
			uid: [],
			first_name: [null, [Validators.required, Validators.pattern(TEXT_REG_EXP)]],
			last_name: [null, [Validators.required, Validators.pattern(TEXT_REG_EXP)]],
			note: [null],
			emails: this.frmBldr.array([]),
			phone_numbers: this.frmBldr.array([])
		});
	}

	ngOnInit(): void {
		// build popup section
		this.buildSectionData();
		//
		if (this.uid) {
			this.contactForm.controls['uid'].setValue(+this.uid);
		}
		if (this.contactId) {
			this.getContact();
		} else {
			this.addMultiControl('emails');
			this.addMultiControl('phone_numbers');
		}
		// detect changes
		this.cDRef.detectChanges();
	}
	/**
	 * Method creates an object with data needed to build a popup section, calls
	 * a method to build the section asynchronously, and sets variables based on the result.
	 */
	private buildSectionData(): void {
		// create object that will need to build the popup section.
		let popupData: any = { slug: this.slug, loaderId: this.loaderId, section: this.section, dialogRef: this.dialogRef };
		// call the build section method and after completion of promise, it will set the `secId` & `section` variables
		this.cacheServ.buildSectionData(popupData).then(() => {
			this.secId = this.cacheServ.secId;
			this.section = this.cacheServ.section;
			// detect changes
			this.cDRef.detectChanges();
		});
	}
	/**
	 *  Getter for easy access to form fields
	 * */
	get f(): { [key: string]: AbstractControl } {
		return this.contactForm.controls;
	}
	/**
	 * Get the form array controls
	 * @param controlName emails/phone_numbers
	 * @returns
	 */
	public getControls(controlName: string): any {
		return (this.contactForm.get(controlName) as FormArray).controls;
	}
	/**
	 * Get the single contact based on contact id
	 */
	private getContact(): void {
		this.apiServ.setLoaderId(this.loaderId);
		this.loader.show(this.loaderId, this.dialogRef);
		this.apiServ.callApiWithPathVariables('GET', 'Contacts', [this.uid, this.contactId]).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.onResultCallback(res, 'contact'));
	}
	/**
	 * Add multi control "emails/phone_numbers"
	 * @param controlName emails/phone_numbers
	 * @param value  used for prefilled multi controls
	 */
	public addMultiControl(controlName: string, value: any = null): void {
		let formArray = <FormArray>this.f[controlName];
		let validators: any = controlName == 'emails' ? [Validators.required, Validators.pattern(EMAIL_REG_EXP)] : [Validators.nullValidator];
		let notificationVal: boolean | null = value ? value.send_notifications : false;
		if(!value && !notificationVal && controlName == 'phone_numbers'){
			notificationVal = this.utilServ.getSendSmsNotifDefVal(true);
		}
		let formGroup = this.frmBldr.group({
			value: [(value ? value.value : null), validators],
			note: [(value ? value.note : null)],
			send_notifications: [notificationVal]
		});
		formArray.push(formGroup);
	}
	/**
	 * Delete multi controls "emails/phone_numbers"
	 * @param controlName emails/phone_numbers
	 * @param index control index
	 */
	public deleteMultiControl(controlName: string, index: any) {
		let formArray: any = <FormArray>this.f[controlName];
		formArray.removeAt(index);
	}
	/**
	 * Filled the profile form
	 */
	private filledForm(): void {
		this.contactForm.addControl('id', new FormControl());
		this.contactForm.patchValue({
			id: +this.contact.id,
			first_name: this.contact.first_name,
			last_name: this.contact.last_name,
			note: this.contact.note,
		});
		// Multi emails
		if (this.contact.emails && (this.contact.emails).length > 0) {
			for (let email of this.contact.emails) {
				this.addMultiControl('emails', email);
			}
		}
		// Multi phone
		if (this.contact.phone_numbers && (this.contact.phone_numbers).length > 0) {
			for (let phone of this.contact.phone_numbers) {
				this.addMultiControl('phone_numbers', phone);
			}
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Check the multi emails/phone_numbers values
	 * @param controlName emails/phone_numbers
	 * @returns
	 */
	private checkMultiControls(controlName: string): boolean {
		let formArray = [];
		if (this.contactForm.controls[controlName].value && (this.contactForm.controls[controlName].value).length > 0) {
			for (let control of this.contactForm.controls[controlName].value) {
				if (control.value) {
					let value = control.value;
					if (controlName == 'phone_numbers') {
						value = this.utilServ.phoneUnmask(value);
					}
					formArray.push(value);
				}
			}
		}
		let exist: boolean = false;
		if (formArray.length > 0) {
			for (var i = 0; i <= formArray.length; i++) {
				for (var j = i; j <= formArray.length; j++) {
					if (i != j && formArray[i] == formArray[j]) {
						exist = true;
					}
				}
			}
		}
		return exist;
	}
	/**
	 * Add error on emails/phone_numbers in case of value is not valid
	 * @param controlName emails/phone_numbers
	 */
	private multiControlsMarkAsTouched(controlName: string): void {
		if (this.contactForm.controls[controlName].value && (this.contactForm.controls[controlName].value).length > 0) {
			let formArray = <FormArray>this.f[controlName];
			(formArray.controls).forEach((control: any) => {
				control.controls['value'].markAsTouched();
			});
		}
	}
	/**
	 * Submit form.
	 */
	public async submitForm() {
		let multiEmailExist = await this.checkMultiControls('emails');
		let multiPhoneExist = await this.checkMultiControls('phone_numbers');
		if (this.contactForm.valid && !multiEmailExist && !multiPhoneExist) {
			this.apiServ.setLoaderId(this.loaderId);
			this.loader.show(this.loaderId, this.dialogRef);
			let sendData: any = this.contactForm.value;
			// Email lowercase
			if (sendData.emails && (sendData.emails).length > 0) {
				for (let control of sendData.emails) {
					control.value = (control.value).toLowerCase();
				}
			}
			// Phone number unmask
			if (sendData.phone_numbers && (sendData.phone_numbers).length > 0) {
				for (let control of sendData.phone_numbers) {
					control.value = this.utilServ.phoneUnmask(control.value);
				}
			}
			// Api call POST/PUT
			let apiMethod: string = this.contactId ? 'PUT' : 'POST';
			this.apiServ.callApi(apiMethod, 'Profile', sendData).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.onResultCallback(res, 'addUpdate'));
		} else {
			if (this.contactForm.valid && (multiEmailExist || multiPhoneExist)) {
				let msg: string = multiEmailExist ? this.initServ.appStr.toastr.duplicateEmails : this.initServ.appStr.toastr.duplicatePhones;
				this.toastr.error(msg);
			} else {
				for (let i in this.contactForm.controls) {
					this.contactForm.controls[i].markAsTouched();
				}
				this.toastr.error('Please fill the required fields marked in red.');
				this.multiControlsMarkAsTouched('emails');
				this.multiControlsMarkAsTouched('phone_numbers');
			}
		}
	}
	/**
	 * On result callback method
	 * @param res API res
	 * @param type
	 * API response handler
	 */
	private onResultCallback(res: any, type: string): void {
		switch (type) {
			case 'contact':
				if (this.apiServ.checkAPIRes(res) && res.data) {
					this.contact = res.data;
					if (this.contact) {
						this.filledForm(); // Filled contact form values
					}
				}
				break;
			case 'addUpdate':
				if (this.apiServ.checkAPIRes(res)) {
					this.toastr.success(res.message);
					this.dialogRef.close(true);
				} else {
					if (res && res.message) {
						this.toastr.error(res.message);
					}
				}
				break;
		}
		this.loader.hide(this.loaderId, this.dialogRef);
		this.cDRef.detectChanges();
	}
}
