import { Component, OnInit, Input, ViewEncapsulation, Self } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
// External lib
import { ToastrService } from 'ngx-toastr';
// Constants
import { PASSWORD_MIN_LENGTH } from 'src/app/Constants';
//Custom validator
import { CustomValidators } from 'src/app/Global/GlobalDefault';
// Interface
interface VerifyEmail extends APIRes { data: {'email_id': string} }
import { APIRes } from 'src/app/Interfaces';
// Services
import { ApiServ, InitServ, NgOnDestroy, RenderComponentServ, SectionServ, UtilServ } from 'src/app/Services';
import { AuthServ } from 'src/app/Core/_services';

@Component({
	selector: 'bk-reset-password-page',
	templateUrl: './ResetPassword.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy, CustomValidators]
})
export class ResetPasswordComponent implements OnInit {

	// Variables
	@Input() secId: string = '';
	pageSett: any;
	// Section fields
	section: any = {
		title: null,
		sub_title: null,
		media: null,
		form: null,
		thank_you_msg: null
	}
	resetForm!: FormGroup; // Reset password form
	loaderId: string = 'reset-loader';
	merchantId: any;
	resetToken: any;
	isbooked: boolean = false;
	fromEmbed: boolean = false;
	verificationToken: any;
	userEmail: string | null = '';
	isProviderFlow: boolean = false;

	// convenience getter for easy access to form fields
	get f(): { [key: string]: AbstractControl } {
		return this.resetForm.controls;
	}

	// eslint-disable-next-line complexity
	constructor(private actRoute: ActivatedRoute, private frmBldr: FormBuilder, public rcServ: RenderComponentServ, public secServ: SectionServ, public initServ: InitServ, private authServ: AuthServ, public utilServ: UtilServ, public toastr: ToastrService, @Self() private destroy: NgOnDestroy, private router: Router, private apiServ: ApiServ) {

		this.resetWithAccessToken();

		this.merchantId = this.initServ.appAdmnStngs?.merchant_id;
		// Reset token get by query params
		this.resetToken = this.actRoute.snapshot.queryParamMap.get('token');
		// isbooked use for show the description on top of the page.
		this.isbooked = (this.actRoute.snapshot.queryParamMap.get('isbooked') && this.actRoute.snapshot.queryParamMap.get('isbooked') == 'true') ? true : false;
		this.fromEmbed = (this.actRoute.snapshot.queryParamMap.get('fromEmbed') && this.actRoute.snapshot.queryParamMap.get('fromEmbed') == 'true') ? true : false;
		// Current login user info from browser local storage
		let currentUser = this.utilServ.appLocalStorage();
		if (this.resetToken != undefined && this.resetToken && !this.initServ.theme) {
			// removed storage in case for without embed and current user and user role.
			if (!currentUser || !this.fromEmbed || (currentUser && currentUser.role == 'customer')) {
				this.authServ.removeCurrentUser();
			}
		}
	}

	ngOnInit(): void {
		// Embed status: true and theme not exist remove the current login user local storage
		if (this.utilServ.embedStatus && !this.initServ.theme) {
			this.authServ.removeCurrentUser();
			this.utilServ.id = null;
		}
		// Forgot password form build
		this.resetForm = this.frmBldr.group({
			new_password: ['', [Validators.required, Validators.minLength(PASSWORD_MIN_LENGTH),
				CustomValidators.strongPasswordValidator()
				]],
			confirm_password: ['', [Validators.required]],
			password_reset_token: [this.resetToken],
			merchant_id: [this.merchantId]
		}, {
			validators: [CustomValidators.matchPassword('new_password', 'confirm_password')]
		});
		// Build section data
		if (this.secId && this.rcServ.pageData) {
			this.pageSett = this.rcServ.pageData.section_settings;
			this.secServ.setServData(this.pageSett, this.rcServ.pageData.content);
			this.section = this.secServ.buildSectionFields(this.secId, this.section, this.rcServ.pageData);
		}
	}

	/**
	 * Resets the state using the access token and determines the flow (provider or user).
	 * If it's a provider flow, triggers the API call to fetch the unverified provider email.
	 */
	private resetWithAccessToken(): void {
		let { verification_code } = this.actRoute.snapshot.params;
		this.verificationToken = verification_code || null;
		this.isProviderFlow = this.actRoute.snapshot.queryParamMap.get('type') === 'provider';

		if(this.isProviderFlow){
			this.getUnverifiedProvEmailApi(this.verificationToken);
		}
	}

	/**
	 * Calls the API to fetch the unverified provider email using the provided token.
	 * @param token - The verification token for the provider email.
	 */
	private getUnverifiedProvEmailApi(token: string): void {
		if (token) {
			this.apiServ.callApiWithPathVariables('GET', 'UnverifiedProvEmail', [token]).pipe(takeUntil(this.destroy)).subscribe((resp: VerifyEmail) => this.handleUnverifiedProvEmailApiResp(resp));
		}
	}

	/**
	 * Handles the response from the unverified provider email API.
	 * Updates the user email if successful, or shows a warning and redirects if the API fails.
	 * @param resp - The response object from the API.
	 */
	private handleUnverifiedProvEmailApiResp(resp: VerifyEmail): void {
		if (this.apiServ.checkAPIRes(resp) && resp?.data) {
			this.userEmail = resp.data?.email_id;
		} else if (resp.api_status == 2) {
			this.toastr.warning(resp.message);
			this.router.navigate(['/signup']);
		}
	}

	/**
	 * Submits the reset form for either provider signup or regular password reset.
	 * Validates the form before submission and handles the flow-specific logic.
	 */
	public submitForm(): void {
		if (this.resetForm.valid) {
			if (this.isProviderFlow) {
				let payload = {
					userName: this.userEmail,
					verification_code: this.verificationToken,
					password: this.resetForm.value.new_password,
					confirm_password: this.resetForm.value.confirm_password
				}
				this.authServ.signupAsPrvdrApi(payload, { redirectUrl: '', isRedirect: true, loaderId: this.loaderId, isSession: true, isSetOldData: true });
			} else {
				this.authServ.passwordResetApi(false, this.resetForm.value, this.loaderId);
			}
		} else {
			this.resetForm.markAllAsTouched();
			this.toastr.error('Please fill the required fields marked in red.');
		}
	}
}
