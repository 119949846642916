/* eslint-disable no-case-declarations */
import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
// Services
import { UtilServ, InitServ } from 'src/app/Services';
import { CalCmnFuncServ } from './CalCmnFunc.service';
import { PrvdrOverriddenTime } from 'src/app/Interfaces';

@Injectable({
	providedIn: 'root'
})
export class OverridePrvdrTimeServ {

	onPrvdrOverriddenTimeChange: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);

	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	prvdrSetts : any;

	constructor(public frmBldr: FormBuilder, public utilServ: UtilServ, public initServ: InitServ, private cmnFunc: CalCmnFuncServ) {
		this.prvdrSetts = this.admnStngs?.merchant_settings?.providers;
	}

	/**
	 * Update the booking form to handle the override of each provider's time.
	 *
	 * @param {FormGroup} BKFrm - The booking form group that contains the form controls and values.
	 *
	 * The function performs the following steps:
	 * 1. Removes the existing 'each_prvdr_time' control from the form group (if it exists).
	 * 2. Adds a new 'each_prvdr_time' control to the form group, initializing it as an empty FormArray.
	 * 3. Checks if the 'override_each_prvdr_time' control is set to true:
	 *	- If true, retrieves the 'provider_details' array from the form group.
	 *	- Converts the 'provider_details' control to a FormArray and assigns its value to the providers variable.
	 *	- If the providers array has elements (checked using utilServ.checkArrLength method), calls the setPrvdrTime method to set the time for each provider in the form group.
	 * Note: Take "any" intentionally bacause its already used in flow, so to change it here, May cause unexpected behaviour
	 */
	public changeOverrideEachPrvdrTime(BKFrm: FormGroup, selectedService: any): void {
		// Remove and add new control for each_prvdr_time
		this.rebuildOverriddenControl(BKFrm);
		// Check if 'override_each_prvdr_time' is enabled
		if(BKFrm.controls['override_each_prvdr_time'].value){
			// Get the 'provider_details' FormArray
			let serviceProviderArray = <FormArray>BKFrm.controls['provider_details'];
			// Extract the providers array from the FormArray
			let providers = serviceProviderArray.value;
			// Check if the providers array is not empty
			if(this.utilServ.checkArrLength(providers)){
				// Set the provider times based on the selected service and providers
				this.setPrvdrTime(BKFrm, selectedService, providers);
			}
		}
	}
	/**
	 * Set the booking time for each provider based on the number of providers in the booking.
	 *
	 * @param {FormGroup} BKFrm - The booking form group that contains the form controls and values.
	 * @param {any} providers - An array of provider details.
	 *
	 * The function performs the following steps:
	 * 1. Uses a switch statement to handle different cases based on the number of providers:
	 *	- Case 0: No providers are present. The function does nothing and exits.
	 *	- Case 1: One provider is present. Calls the setPrvdrTime method to set the time for the single provider.
	 *	- Default: More than one provider is present. Iterates over the providers array:
	 *		- Retrieves the booking length time from the 'provider_length' control in the form group.
	 *		- Calls the addPrvdrTime method to add the time for each provider using their ID and the retrieved booking length time.
	 * Note: Take "any" intentionally bacause its already used in flow, so to change it here, May cause unexpected behaviour
	 */
	private setPrvdrTime(BKFrm: FormGroup, selectedService: any, providers: any): void {
		// Switch based on the number of providers
		switch(+(providers).length){
			case 0:
				// If there are no providers, do nothing
				break;
			case 1:
				// If there is only one provider, set the time for the team or single (team or single provider case)
				this.setPrvdrTimeForTeam(BKFrm, selectedService, providers);
				break;
			default:
				this.setPrvdrTimeForPair(BKFrm, selectedService, providers);
				break;
		}
	}
	/**
	 * Sets provider time for a pair of providers within the given FormGroup.
	 *
	 * @param BKFrm - The FormGroup where provider time needs to be set.
	 * @param selectedService - The selected service object that may affect provider pay settings.
	 * @param providers - An array containing details of the providers.
	 * Note: Take "any" intentionally bacause its already used in flow, so to change it here, May cause unexpected behaviour
	 */
	private setPrvdrTimeForPair(BKFrm: FormGroup, selectedService: any, providers: any): void {
		// For multiple providers, iterate through each provider
		for(let provider of providers){
			// Get the default time from the form control 'provider_length'
			let time = BKFrm.controls['provider_length'].value;
			// Check if the service and booking override provider pay settings
			if(selectedService?.override_provider_pay && BKFrm?.value?.override_provider_pay){
				let prvdrsLength = (providers).length;
				// Adjust the time based on the number of providers
				time = this.overridePrvdrDefaultTimeForAdjustedCase(BKFrm, time, prvdrsLength);
			}
			// Add the provider time to the form
			this.addPrvdrTime(BKFrm, provider?.id, time)
		}
	}
	/**
	 * Set the booking time for a team of providers or a single provider.
	 *
	 * @param {FormGroup} BKFrm - The booking form group that contains the form controls and values.
	 * @param {any} providers - An array containing provider details.
	 *
	 * The function performs the following steps:
	 * 1. Checks if the first provider in the array is of type 'team':
	 *		- If true, checks if the team has members and if the members array is not empty:
	 *			- Iterates over each member of the team:
	 *			- If a member has a base pay defined in their wages, retrieves the booking length time from the 'provider_length' control in the form group.
	 *			- Calls the addPrvdrTime method to add the time for each member using their ID and the retrieved booking length time.
	 * 2. If the provider is not of type 'team':
	 *		- Retrieves the booking length time from the 'provider_length' control in the form group.
	 *		- Calls the addPrvdrTime method to add the time for the single provider using their ID and the retrieved booking length time.
	 *
	 * Note: Take "any" intentionally bacause its already used in flow, so to change it here, May cause unexpected behaviour
	 */
	private setPrvdrTimeForTeam(BKFrm: FormGroup, selectedService: any, providers: any): void {
		// Check if the first provider is of type 'team'
		if(providers[0].provider_type == 'team'){
			// If the team has members, iterate through each member
			if(providers[0].members && (providers[0].members).length > 0){
				// If the member has a base pay in wages, calculate and set the time
				for(let member of providers[0].members){
					// If the member has a base pay in wages, calculate and set the time
					// !Comment following condition, because requirement change and need to show override time with non-lead member, even payment only goes to lead
					// if(member?.wages?.base_pay){
						// Get the default time from the form control 'provider_length'
						let time = BKFrm.controls['provider_length'].value;
						// Calculate the time for the team member based on the selected service and providers
						time = this.calcTimeForTeam(BKFrm, selectedService, providers, time);
						// Add the calculated time for the team member to the form
						this.addPrvdrTime(BKFrm, +member?.id, time)
					// }
				}
			}
		}else{
			// If the provider is not a team, get the default time and set it for the individual provider
			let time = BKFrm.value.provider_length;
			// Add the default time for the individual provider to the form
			this.addPrvdrTime(BKFrm, +providers[0]?.id, time)
		}
	}
	/**
	 * Calculates the time for a team based on certain conditions and parameters.
	 * @param BKFrm The form data.
	 * @param selectedService The selected service.
	 * @param providers The array of providers or provider details.
	 * @param time The initial time.
	 * @returns The calculated time for the team.
	 * Note: Take "any" intentionally bacause its already used in flow, so to change it here, May cause unexpected behaviour
	 */
	private calcTimeForTeam(BKFrm: FormGroup, selectedService: any, providers: any, time: number): number {
		// Check if selected service and form data exist, and if they have override_provider_pay property set
		if(selectedService?.override_provider_pay && BKFrm?.value?.override_provider_pay){
			// Check if payment goes to the lead provider
			if(providers[0]?.payment_goes_to == 'lead'){
				// Calculate team lead time for an overridden provider
				return this.calcTeamLeadTimeForOverriddenPrvdr(BKFrm, selectedService);
			} else {
				// Calculate overridden provider's default time for an adjusted case
				let prvdrsLength = (providers[0].members).length;
				return this.overridePrvdrDefaultTimeForAdjustedCase(BKFrm, time, prvdrsLength);
			}
		}
		// If conditions are not met, return the initial time
		return time;
	}
	/**
	 * Calculates the total booking time for a team lead in the context of an overridden provider.
	 * @param BKFrm The form data.
	 * @param selectedService The selected service.
	 * @returns The total booking time for the team lead.
	 * Note: Take "any" intentionally bacause its already used in flow, so to change it here, May cause unexpected behaviour
	 */
	private calcTeamLeadTimeForOverriddenPrvdr(BKFrm: FormGroup, selectedService: any): number {
		// Retrieve the total booking time from the form data, considering adjustments
		let bookingTotalTime = BKFrm?.value.adjust_time ? +BKFrm?.value.adjusted_time : BKFrm?.value.length;
		// If the selected service is hourly
		if(selectedService.is_hourly_service == 'yes'){
			// Check if adjustments are made specifically for hourly services
			if(BKFrm?.value?.adjust_time_hourly){
				bookingTotalTime = BKFrm?.value?.service_hourly_value; // Use adjusted hourly value
			}else if(this.cmnFunc.ifPriceBaseOfHSCustom(selectedService)){
				bookingTotalTime = BKFrm?.value?.service_hourly_value; // Consider service's hourly value
				// If additional time specified in the form, add it to the bookingTotalTime
				if(BKFrm?.value?.add_fields_time){
					bookingTotalTime += BKFrm?.value.add_fields_time;
				}
			}
		}
		// Return the calculated total booking time
		return +bookingTotalTime;
	}
	/**
	 * Calculates the default time for an adjusted case for overridden providers.
	 * @param BKFrm The form data.
	 * @param time The initial time.
	 * @param prvdrsLength The length of providers.
	 * @returns The calculated default time for the adjusted case.
	 */
	private overridePrvdrDefaultTimeForAdjustedCase(BKFrm: FormGroup, time: number, prvdrsLength: number ):number {
		// Check if adjustments are made in the form data
		if(BKFrm?.value?.adjust_time && BKFrm?.value?.adjusted_time > 0){
			// Divide the initial time by the number of providers
			time = +(Number(time / prvdrsLength).toFixed(0));
			// Ensure that the calculated time is not negative
			return  time < 0 ? 0 : time;
		}
		// If adjustments are not made, return the initial time
		return time
	}
	/**
	 * Add a provider's time to the 'each_prvdr_time' FormArray in the booking form.
	 *
	 * @param {FormGroup} BKFrm - The booking form group that contains the form controls and values.
	 * @param {string} providerId - The ID of the provider whose time is being added.
	 * @param {string} time - The time to be assigned to the provider.
	 *
	 * The function performs the following steps:
	 * 1. Retrieves the 'each_prvdr_time' FormArray from the form group.
	 * 2. Creates a new FormGroup for the provider's time with the following controls:
	 *		- 'provider_id': A form control initialized with the providerId.
	 *		- 'time': A form control initialized with the time.
	 * 3. Pushes the new FormGroup to the 'each_prvdr_time' FormArray.
	 */
	private addPrvdrTime(BKFrm: FormGroup, providerId: number, time: number): void {
		let providerTimes = <FormArray>BKFrm.get('each_prvdr_time');
		providerTimes.push(this.frmBldr.group({
			provider_id: [providerId],
			time: [time]
		}));
		// Emit the boolean value `true` through the onPrvdrOverriddenTimeChange Subject.
		// This will notify all subscribers to onPrvdrOverriddenTimeChange that the provider's overridden time has changed.
		// The emitted value can trigger any logic that is subscribed to this observable, such as resetting the form or updating the UI
		this.onPrvdrOverriddenTimeChange.next(true);
	}
	/**
	 * Adjusts the provider_length to account for a new provider being added.
	 * @param BKFrm A FormGroup instance representing the form data.
	 * @param providerId An ID representing the provider to which the time will be added.
	 */
	public addOverrideTimeForPairNewMember(BKFrm: FormGroup, providerId: number): void {
		// Check if override_each_prvdr_time flag is set in the form data
		if(BKFrm?.value?.override_each_prvdr_time){
			// Retrieve the list of providers from the form data
			let providers = BKFrm?.value?.provider_details;
			// Determine the current number of providers
			let currentNumOfPrvdrs = (providers).length ? (providers).length : 0;
			// Calculate the previous number of providers
			let prevNumOfPrvdrs = currentNumOfPrvdrs - 1;
			// Retrieve the initial provider length
			let time = BKFrm?.value?.provider_length;
			if(this.cmnFunc.isValExist(this.admnStngs.merchant_settings.providers.divide_providers_job_length) && this.admnStngs?.merchant_settings?.providers?.divide_providers_job_length == 'yes'){
				// If there were previous providers, adjust the total time accordingly
				if(prevNumOfPrvdrs > 0){
					time *= prevNumOfPrvdrs;
				}
				// Distribute the total time evenly among the current number of providers
				time = +(Number(time / currentNumOfPrvdrs).toFixed(0));
			}
			if(BKFrm?.value?.adjust_time){
				time = +BKFrm?.value?.adjusted_time;
			}
			// Ensure the calculated time is not negative
			time = time > 0 ? time : 0;
			// Add the calculated time to the specified provider
			this.addPrvdrTime(BKFrm, +providerId, time);
		}
	}
	/**
	 * Removes the time associated with a specific provider from the each_prvdr_time array in the form.
	 * @param BKFrm A FormGroup instance representing the form data.
	 * @param providerId An ID representing the provider whose time entry needs to be removed.
	 */
	public rmvPairMemberTime(BKFrm: FormGroup, providerId: number): void {
		// Cast the each_prvdr_time control to a FormArray
		let eachPrvdrTimeArray = <FormArray>BKFrm.controls['each_prvdr_time'];
		// Find the index of the provider within the FormArray based on the providerId
		let index = eachPrvdrTimeArray.controls.findIndex(x => x?.value?.provider_id == providerId);
		// Remove the provider's time entry from the FormArray using the index
		eachPrvdrTimeArray.removeAt(index);
	}
	/**
	 * This method manages the overridden time settings for each provider in the form group.
	 * It first rebuilds the form controls related to overridden time.
	 * If the 'override_each_prvdr_time' control's value is truthy, it proceeds to update the providers' overridden times.
	 *
	 * @param BKFrm - The form group containing provider details and overridden time settings.
	 * @param copyOverrideTimeObj - An array of PrvdrOverriddenTime objects to be used for updating the form.
	 */
	public keepOverrideEachPrvdrTime(BKFrm: FormGroup, copyOverrideTimeObj: PrvdrOverriddenTime[]): void {
		// Remove and add new control for each_prvdr_time
		this.rebuildOverriddenControl(BKFrm);
		// Check if the 'override_each_prvdr_time' control's value is truthy
		if(BKFrm.controls['override_each_prvdr_time'].value){
			// Cast the 'provider_details' control to a FormArray
			let serviceProviderArray = <FormArray>BKFrm.controls['provider_details'];
			// Get the current value of the provider details array
			let providers = serviceProviderArray.value;
			// If the providers array is not empty, update the providers' overridden times
			if(this.utilServ.checkArrLength(providers)){
				this.keepPrvdrTime(BKFrm, copyOverrideTimeObj, providers);
			}else{
				BKFrm.controls['override_each_prvdr_time'].setValue(false);
			}
		}
	}
	/**
	 * This method handles the provider times based on the number of providers.
	 * It uses a switch statement to call different methods for handling zero, one, or multiple providers.
	 *
	 * @param BKFrm - The form group containing provider details and overridden time settings.
	 * @param copyOverrideTimeObj - An array of PrvdrOverriddenTime objects to be used for updating the form.
	 * @param providers - An array of provider details.
	 * Note: Take "any" intentionally bacause its already used in flow, so to change it here, May cause unexpected behaviour
	 */
	private keepPrvdrTime(BKFrm: FormGroup, copyOverrideTimeObj: PrvdrOverriddenTime[], providers: any): void {
		// Use a switch statement based on the number of providers
		switch(+(providers).length){
			case 0:
				// If there are no providers, do nothing
				break;
			case 1:
				// If there is exactly one provider, handle it as a team
				this.keepPrvdrTimeForTeam(BKFrm, copyOverrideTimeObj, providers);
				break;
			default:
				// If there are two or more providers, handle it as a pair
				this.keepPrvdrTimeForPair(BKFrm, copyOverrideTimeObj, providers);
				break;
		}
	}
	/**
	 * This method updates the overridden time settings for pairs of providers.
	 * It calculates the time for each provider based on the settings and applies it to the form group.
	 *
	 * @param BKFrm - The form group containing provider details and overridden time settings.
	 * @param copyOverrideTimeObj - An array of PrvdrOverriddenTime objects to be used for updating the form.
	 * @param providers - An array of provider details.
	 * Note: Take "any" intentionally bacause its already used in flow, so to change it here, May cause unexpected behaviour
	 */
	private keepPrvdrTimeForPair(BKFrm: FormGroup, copyOverrideTimeObj: PrvdrOverriddenTime[], providers: any): void {
		// Get the settings for provider override time
		let outCheckSetts = this.checkSettsForPrvdrOverrideTime(BKFrm, providers, copyOverrideTimeObj);
		let prvdrLength = outCheckSetts.prvdrLength;
		let dividedEquallyTime = outCheckSetts.dividedEquallyTime;
		let increaseDecreaseSetts = outCheckSetts.increaseDecreaseSetts;
		// If the settings are not "reset", update the time for each provider
		if(increaseDecreaseSetts != "reset"){
			let i : number = 0;
			// Iterate over each provider
			for(let provider of providers){
				// Calculate the time for the current provider based on the settings
				let time = this.setIncreasedDecreasedMemberTimeBySett(copyOverrideTimeObj[i], dividedEquallyTime, prvdrLength,  increaseDecreaseSetts);
				// Update the time for the current provider in the form group
				this.addPrvdrTime(BKFrm, provider?.id, time)
				i++;
			}
		}
	}
	/**
	 * This method manages the overridden time settings for providers identified as teams.
	 * It calculates and applies the overridden time settings to team members individually,
	 * or defers to handling single providers if not identified as a team.
	 *
	 * @param BKFrm - The form group containing provider details and overridden time settings.
	 * @param copyOverrideTimeObj - An array of PrvdrOverriddenTime objects to be used for updating the form.
	 * @param providers - An array of provider details, including provider type and members for teams.
	 * Note: Take "any" intentionally bacause its already used in flow, so to change it here, May cause unexpected behaviour
	 */
	private keepPrvdrTimeForTeam(BKFrm: FormGroup, copyOverrideTimeObj: PrvdrOverriddenTime[], providers: any): void {
		// Check if the provider is identified as a team
		if(providers[0].provider_type == 'team'){
			// Get settings for provider override time based on team members
			let outCheckSetts = this.checkSettsForPrvdrOverrideTime(BKFrm, providers[0].members, copyOverrideTimeObj);
			let prvdrLength = outCheckSetts.prvdrLength;
			let dividedEquallyTime = outCheckSetts.dividedEquallyTime;
			let increaseDecreaseSetts = outCheckSetts.increaseDecreaseSetts;

			// If the settings are not "reset", update the time for each team member
			if(increaseDecreaseSetts != "reset"){
				if(providers[0].members && (providers[0].members).length > 0){
					let i : number = 0;
					// Iterate over each team member
					for(let member of providers[0].members){
						// Calculate the time for the current team member based on the settings
						let time = this.setIncreasedDecreasedMemberTimeBySett(copyOverrideTimeObj[i], dividedEquallyTime, prvdrLength,  increaseDecreaseSetts);
						//Update the time for the current team member in the form group
						this.addPrvdrTime(BKFrm, +member?.id, time)
						i++;
					}
				}
			}
		}else{
			// If the provider is not identified as a team, handle it as a single provider
			this.keepPrvdrTimeForSingle(BKFrm, copyOverrideTimeObj, providers);
		}
	}
	/**
	 * This method manages the overridden time settings for single providers.
	 * It calculates and applies the overridden time settings to the single provider in the form group.
	 *
	 * @param BKFrm - The form group containing provider details and overridden time settings.
	 * @param copyOverrideTimeObj - An array of PrvdrOverriddenTime objects to be used for updating the form.
	 * @param providers - An array containing details of the single provider.
	 * Note: Take "any" intentionally bacause its already used in flow, so to change it here, May cause unexpected behaviour
	 */
	private keepPrvdrTimeForSingle(BKFrm: FormGroup, copyOverrideTimeObj: PrvdrOverriddenTime[], providers: any): void {
		// Get the settings for provider override time based on the single provider
		let outCheckSetts = this.checkSettsForPrvdrOverrideTime(BKFrm, providers, copyOverrideTimeObj);
		let prvdrLength = outCheckSetts.prvdrLength;
		let dividedEquallyTime = outCheckSetts.dividedEquallyTime;
		let increaseDecreaseSetts = outCheckSetts.increaseDecreaseSetts;

		// If the settings are not "reset", update the time for the single provider
		if(increaseDecreaseSetts != "reset"){
			// Calculate the time for the single provider based on the settings
			let time = this.setIncreasedDecreasedMemberTimeBySett(copyOverrideTimeObj[0], dividedEquallyTime, prvdrLength,  increaseDecreaseSetts);
			// Update the time for the single provider in the form group
			this.addPrvdrTime(BKFrm, +providers[0]?.id, time)
		}
	}
	/**
	 * This method compares the lengths of current and previous provider arrays to determine
	 * if the provider count has increased, decreased, or remained unchanged.
	 *
	 * @param currentPrvdrs - The current array of providers.
	 * @param prevPrvdrs - The previous array of PrvdrOverriddenTime objects.
	 * @returns A string indicating the change in provider count:
	 *          - "prvdr_count_increase_override_time_behavior" if the provider count has increased.
	 *          - "prvdr_count_decrease_override_time_behavior" if the provider count has decreased.
	 *          - "equal" if the provider count remains unchanged.
	 * Note: Take "any" intentionally bacause its already used in flow, so to change it here, May cause unexpected behaviour
	 */
	private checkIncreaseOrDecreasePrvdrLength(currentPrvdrs: any, prevPrvdrs: PrvdrOverriddenTime[]): string {
		let currentPrvdrsLength = currentPrvdrs.length;
		let prevPrvdrsLength = prevPrvdrs.length;
		if(currentPrvdrsLength > prevPrvdrsLength){
			return "prvdr_count_increase_override_time_behavior";
		}else if(currentPrvdrsLength < prevPrvdrsLength){
			return "prvdr_count_decrease_override_time_behavior";
		}
		return "equal";
	}
	/**
	 * This method calculates the average overridden time per new provider based on the total overridden time
	 * from previous providers and the number of new providers.
	 *
	 * @param providers - The new array of providers.
	 * @param copyOverrideTimeObj - An array of PrvdrOverriddenTime objects containing overridden time settings for previous providers.
	 * @returns The average overridden time per new provider, rounded to the nearest whole number.
	 * Note: Take "any" intentionally bacause its already used in flow, so to change it here, May cause unexpected behaviour
	 */
	private dividePrevPrvdrsLenToNewPrvdrs(providers: any, copyOverrideTimeObj: PrvdrOverriddenTime[]): number {
		// Calculate the number of new providers
		let newPrvdrsLength = providers.length;
		// Calculate the total overridden time from previous providers
		let totalLength = this.sumOfPrevPrvdrsOverriddenTime(copyOverrideTimeObj);
		// Calculate the average overridden time per new provider
		let prvdrLength = +(Number(totalLength / newPrvdrsLength).toFixed(0));
		// Return the calculated average overridden time per new provider, defaulting to 0 if undefined or NaN
		return prvdrLength ? prvdrLength : 0;
	}
	/**
	 * Calculates the total overridden time from a given array of PrvdrOverriddenTime objects.
	 *
	 * @param copyOverrideTimeObj - An array of PrvdrOverriddenTime objects containing overridden time settings for previous providers.
	 * @returns The sum of overridden times from all PrvdrOverriddenTime objects in the array.
	 */
	private sumOfPrevPrvdrsOverriddenTime(copyOverrideTimeObj: PrvdrOverriddenTime[]): number {
		let totalLength = 0;
		// Ensure copyOverrideTimeObj is not null, undefined, or empty
		if(copyOverrideTimeObj && (copyOverrideTimeObj).length > 0){
			// Iterate over each PrvdrOverriddenTime object and sum their 'time' property
			for(let prvdr of copyOverrideTimeObj){
				totalLength += prvdr.time
			}
		}
		// Return the accumulated total overridden time
		return totalLength;
	}
	/**
	 * Evaluates settings related to provider override time based on the current form group,
	 * the array of providers, and previous overridden time settings.
	 *
	 * @param BKFrm - The form group containing provider details and overridden time settings.
	 * @param providers - An array containing details of the current providers.
	 * @param copyOverrideTimeObj - An array of PrvdrOverriddenTime objects representing previous overridden time settings.
	 * @returns An object containing evaluated settings:
	 *          - prvdrLength: The expected length of providers.
	 *          - dividedEquallyTime: Distribution of overridden time settings among new providers.
	 *          - increaseDecreaseSetts: Settings indicating adjustments based on provider count changes.
	 * Note: Take "any" intentionally bacause its already used in flow, so to change it here, May cause unexpected behaviour
	 */
	private checkSettsForPrvdrOverrideTime(BKFrm: FormGroup, providers: any, copyOverrideTimeObj: PrvdrOverriddenTime[]){
		// Retrieve expected provider length from the form group
		let prvdrLength = BKFrm.value.provider_length;
		// Calculate distribution of overridden time settings among new providers
		let dividedEquallyTime = this.dividePrevPrvdrsLenToNewPrvdrs(providers, copyOverrideTimeObj)
		// Determine if there has been an increase, decrease, or no change in provider count
		let prvdrLengthStatus = this.checkIncreaseOrDecreasePrvdrLength(providers, copyOverrideTimeObj);
		// Initialize increaseDecreaseSetts with "equal" string
		let increaseDecreaseSetts : string = "equal";
		// If there's been a change in provider count, assign corresponding settings
		if(prvdrLengthStatus !== "equal"){
			increaseDecreaseSetts = this.prvdrSetts?.[prvdrLengthStatus] ? this.prvdrSetts?.[prvdrLengthStatus] : "divide_equally";
		}
		// If increaseDecreaseSetts indicates a reset, update form group and rebuild controls
		if(increaseDecreaseSetts == "reset"){
			BKFrm.controls['override_each_prvdr_time'].setValue(false);
			// Remove and add new control for each_prvdr_time
			this.rebuildOverriddenControl(BKFrm);
		}
		// Return evaluated settings as an object
		return {
			prvdrLength,
			dividedEquallyTime,
			increaseDecreaseSetts
		}
	}
	/**
	 * Sets the increased or decreased overridden time for a member based on specified settings.
	 *
	 * @param overrideTimeObj - The PrvdrOverriddenTime object containing overridden time settings.
	 * @param dividedEquallyTime - Distributed overridden time for new providers.
	 * @param prvdrLength - Expected length of providers.
	 * @param increaseDecreaseSetts - Settings indicating how to adjust overridden time.
	 * @returns The adjusted overridden time for the member.
	 */
	private setIncreasedDecreasedMemberTimeBySett(overrideTimeObj: PrvdrOverriddenTime, dividedEquallyTime: number, prvdrLength: number, increaseDecreaseSetts: string): number{
		switch(increaseDecreaseSetts){
			case 'divide_equally':
				return dividedEquallyTime
			case 'add_time_for_new':
				return this.addTimeForNew(overrideTimeObj, prvdrLength);
			case 'set_by_index':
			case 'equal':
				return overrideTimeObj?.time
			default:
				return overrideTimeObj?.time
		}
	}
	/**
	 * Calculates additional overridden time for new providers based on specific conditions.
	 *
	 * @param overrideTimeObj - The PrvdrOverriddenTime object containing overridden time settings.
	 * @param prvdrLength - Expected length of providers.
	 * @returns The additional overridden time for new providers.
	 */
	private addTimeForNew(overrideTimeObj: PrvdrOverriddenTime, prvdrLength: number): number {
		if(overrideTimeObj?.time){
			// Return overridden time from overrideTimeObj if available
			return overrideTimeObj?.time
		}else if(this.prvdrSetts?.default_override_time_for_increased_members != "zero"){
			// Return prvdrLength if default_override_time_for_increased_members is not "zero"
			return prvdrLength;
		}
		// Return 0 if no conditions are met
		return 0;
	}
	/**
	 * Rebuilds the 'each_prvdr_time' FormArray within the provided FormGroup.
	 *
	 * @param BKFrm - The FormGroup where the 'each_prvdr_time' FormArray needs to be rebuilt.
	 */
	private rebuildOverriddenControl(BKFrm: FormGroup): void {
		// Remove the existing 'each_prvdr_time' FormArray if it exists
		BKFrm?.removeControl('each_prvdr_time');
		// Add a new empty 'each_prvdr_time' FormArray to the form
		BKFrm?.addControl('each_prvdr_time',this.frmBldr.array([]));
	}
	/**
	 * Sets the overridden time for each provider in the booking form based on prefilled data.
	 *
	 * @param BKFrm - The booking form group containing form controls and form arrays.
	 * @param prefilledData - The prefilled data containing override settings and provider times.
	 * Note: Take "any" intentionally bacause its already used in flow, so to change it here, May cause unexpected behaviour
	 */
	public setPrvdrOverriddenTimeForSamePrvdr (BKFrm: FormGroup, prefilledData: any): void {
		// Check if the prefilled data indicates to override each provider's time
		if(prefilledData?.override_each_prvdr_time){
			// Enable the 'override_each_prvdr_time' setting by setting its value to true
			BKFrm.controls['override_each_prvdr_time'].setValue(true);
			// Remove the existing 'each_prvdr_time' FormArray from the form
			this.rebuildOverriddenControl(BKFrm);
			// Check if prefilledData contains provider times
			if(prefilledData?.each_prvdr_time && (prefilledData.each_prvdr_time).length > 0){
				// Iterate through each provider in the prefilledData
				for(let prvdr of prefilledData.each_prvdr_time){
					// Add the provider's overridden time to the form
					this.addPrvdrTime(BKFrm, +prvdr.provider_id, prvdr.time)
				}
			}
		}
	}
	/**
	 * Disable the 'override_each_prvdr_time' setting and rebuilds the 'each_prvdr_time' FormArray within the provided FormGroup.
	 *
	 * @param BKFrm - The FormGroup where the 'each_prvdr_time' FormArray needs to be reset.
	 */
	public emptyPrvdrOverriddenTime(BKFrm: FormGroup): void {
		// Disable the 'override_each_prvdr_time' setting by setting its value to false
		BKFrm.controls['override_each_prvdr_time'].setValue(false);
		// Remove and add new control for each_prvdr_time
		this.rebuildOverriddenControl(BKFrm);
	}
}
