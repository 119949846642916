import { Component, Input, Output, OnInit, Self, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, FormControl, FormBuilder } from '@angular/forms';
// External lib
import Bugsnag from '@bugsnag/js';
// Service
import { NgOnDestroy, InitServ, SchedulingServ, ApiServ, UtilServ, LoaderServ, DateServ, SectionServ, BkngFormServ, OverridePrvdrTimeServ } from '../../../Services';
import { BkngFormPopupServ } from '../BkngFormPopup.service';
import { distinctUntilChanged, takeUntil } from 'rxjs';
@Component({
	selector: 'bk-service-provider',
	templateUrl: './ServiceProvider.component.html',
	providers: [NgOnDestroy]
})
export class ServiceProviderComponent implements OnInit {

	@Input() ServiceProviderControl!: FormGroup;
	@Input() isQuote : any;
	@Input() bookingType : string = 'add';
	@Input() section: any;
	@Input() formLayout : string = 'two_step';
	@Input() prefilledData : any;
	@Input() settings : any;
	@Input() dayDiscountApplyToAll : any;
	@Input() dayDiscountBookings : any;
	@Input() dayDiscounts : any;
	@Input() dateDiscountsArray : any;
	@Input() dayDiscountsArray : any;
	@Input() pageSett: any;
	@Input() selectedServiceType : any;
	@Input() isCustomerAllowedRes: any;
	@Input() calSection: any;
	// eslint-disable-next-line @angular-eslint/no-output-on-prefix
	@Output() onBookingDateChange: EventEmitter<any> = new EventEmitter();
	// eslint-disable-next-line @angular-eslint/no-output-on-prefix
	@Output() onSpotChangeCallback: EventEmitter<any> = new EventEmitter();

	@Output() resetPrvdrOverriddenTime : EventEmitter<any> = new EventEmitter();

	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	currentUser = this.utilServ.appLocalStorage(); // Current login user info from browser local storage
	globals : any;

	allSpots: any = this.initServ.appBookingSpots;
	provLoaderId: string = 'selected-prov';

	customerScheduleOptions : any = []
	selectedScheduleOptions : any = []
	isWaitingListVal : boolean = false;
	customerDetails : any;
	previousProviders: any;
	availableProviderIds : any;
	allBookingSpots : any;
	isAllDataLoaded : boolean = false
	availProvsData: any;
	availProvFilters : any;
	selectedDate: any;
	provider:any;
	providers: any;
	reloadProvider: boolean = false;
	showSelectedProvider : boolean = false;
	slotsBuilt : boolean = true
	scheduleRange : any = this.scheduleServ.getScheduleRange();
	recurringDates: any;
	recurringDatesArray: any;
	checkAvailabilityFor : any = 'all';
	perDateAvailSpots : any= {};
	availableDates : any;
	notAvailableDates : any;
	selectedDateForSlot: any;
	availableProviders : any= {};
	minProvidersReq : number = 1;
	bookinglength: any;
	teamMembers : any;
	teamIds : any;

	preBookedProviders : any;
	bookingScheduledDay : any;
	currProviderSpotStatus : any;
	existingBookingDate : any;
	existingArrivalDate : any;
	existingArrivalTime : any;
	existingWages : any;
	existingReimbursements: any;
	existingWagesReimbursements : any;
	existingProviderAvailability : any;
	existingProviders : any = [];
	showRescheduleBtn : boolean = true;
	isReschedule : boolean = false;
	arrival_date : any;
	sameProvider : any = [];
	isCurrentProviderBlock : boolean = false;
	isProviderEditable : boolean = false;
	isRecButtonVisibility : boolean = false;
	isSameProviderApplicable : boolean = false;
	isSpotAvailable : boolean = false;
	formattedDate : string = '';
	descStatus: boolean = false;
	holidays : any;
	isMultiCalReload : boolean = true; // For refresh the multi step calender

	constructor(public initServ: InitServ, @Self() private destroy: NgOnDestroy, private bkngFormPopupServ: BkngFormPopupServ, public scheduleServ : SchedulingServ, private apiServ: ApiServ, public utilServ : UtilServ,public cDRef: ChangeDetectorRef, private loader: LoaderServ, public dateServ : DateServ, private secServ: SectionServ, public bkngFormServ: BkngFormServ, public frmBldr: FormBuilder, public overridePrvdrTimeServ: OverridePrvdrTimeServ){}

	/* Init function to load the required data for scheduling*/
	// eslint-disable-next-line complexity
	ngOnInit(): void {
		// descStatus use in reschedule
		if(this.section && this.section.desc){
			this.descStatus = this.secServ.checkEleStatus(this.pageSett, this.section.desc_id);
		}
		//Get customer details.
		this.customerDetails = this.initServ.userInfo;
		this.globals = this.scheduleServ.globals
		// For Manual scheduling set this variable
		this.isWaitingListVal = this.ServiceProviderControl.controls['is_waiting_list'].value;
		this.setProviderSelectOptions();
		// this.getAllBookingSpots();
		this.isProviderDisplayOn()
		// TODO use common function for following variables
		if(this.ServiceProviderControl.controls['adjust_time'].value){
			this.bookinglength = this.ServiceProviderControl.controls['adjusted_time'].value
		}else{
			this.bookinglength = this.ServiceProviderControl.controls['length'].value
		}
		if(this.globals.providerMaxTime > 0 && this.bookinglength > this.globals.providerMaxTime){
			this.minProvidersReq = Math.ceil(this.bookinglength/this.globals.providerMaxTime)
		}
		this.ServiceProviderControl.controls['provider_type'].setValue('random_provider');
		if(this.initServ.appAdmnStngs.merchant_settings?.providers?.scheduling_type && this.initServ.appAdmnStngs.merchant_settings?.providers?.scheduling_type == 'manually'){
			this.ServiceProviderControl.controls['provider_type'].setValue('unassigned');
			this.ServiceProviderControl.controls['booking_priority'].setValue('medium');

			/** if already date/time selected and move from step one to two again. **/
			let existingArrivalDate = this.ServiceProviderControl.controls['arrival_date'].value;
			if(existingArrivalDate){
				let existingBookingDate = this.ServiceProviderControl.controls['booking_date'].value;
				this.selectedDate = existingBookingDate;
				let arrival_date = this.setDateValue(existingBookingDate);
				this.ServiceProviderControl.controls['arrival_date'].setValue(arrival_date);
			}
		}
		this.checkIfQuoteHasTime();
		if(this.bookingType == 'reschedule'){
			// We change the order of these functions, because its impact the apply_to_all value, after we launching the option for default value of apply_to_all
			this.prefilledRescData();
			this.isProviderEditable = this.scheduleServ.isProviderEditable(this.prefilledData, this.selectedServiceType);
			this.isRecButtonVisibility = this.checkRecurringButtonVisibility();
		}
		this.ServiceProviderControl.controls['frequency'].valueChanges.pipe(distinctUntilChanged(),takeUntil(this.destroy)).subscribe(() => {
			if(this.bookingType == 'reschedule' && this.prefilledData){
				this.isRecButtonVisibility = this.checkRecurringButtonVisibility();
			}
		});

		/*this.ServiceProviderControl.controls['location_id'].valueChanges.pipe(distinctUntilChanged(),takeUntil(this.destroy)).subscribe(value => {
			console.log(11111,value)
			this.getAllBookingSpots();
		});*/
	}
	private checkIfQuoteHasTime(){
		if(this.isQuote && this.prefilledData && this.prefilledData.arrival_date && this.prefilledData.arrival_time){
			// Get the current timestamp in seconds
			let currTS: any = Math.floor(Date.now()/1000);
			let hours: any = Math.floor(+this.prefilledData?.arrival_time/100);
			let minutes: any = +this.prefilledData?.arrival_time%100;
			// Calculate the timestamp of the booking arrival time using the utilServ service
			let bkngTS: any = this.utilServ.convertToTimestamp(this.utilServ.createDateObjLocal(this.prefilledData?.booking_date, hours, minutes));
			if(bkngTS > currTS){
				setTimeout(()=>{
					this.prefilledQuoteDateTime(this.prefilledData.arrival_date);
				},500)
			}
		}
	}
	/**
	 * Function to prefilled quote date.
	 */
	prefilledQuoteDateTime(selectedDate: any): void {
		// this.ServiceProviderControl.controls['same_day_booking'].setValue(this.prefilledData?.same_day_booking);
		this.checkSameDayBooking(this.prefilledData.booking_date);
		if (selectedDate) {
			let arrival_date = this.setDateValue(this.prefilledData.booking_date);
			this.ServiceProviderControl.controls['arrival_date'].setValue(arrival_date);
			let frequencyOccurance = this.ServiceProviderControl.controls['frequency_repeat_slug'].value;
			this.recurringDatesArray = this.scheduleServ.getRecurringDates(this.prefilledData.booking_date, frequencyOccurance, this.scheduleRange, this.globals.totalBookingsCount,this.holidays, this.globals.skipHolidays);
			if(this.recurringDatesArray){
				this.recurringDates = this.recurringDatesArray.toString();
			}else{
				this.recurringDates = null;
			}
			this.ServiceProviderControl.controls['recurring_dates'].setValue(this.recurringDates);
			this.selectedDate = this.prefilledData.booking_date;
			if(this.prefilledData.arrival_time){
				this.ServiceProviderControl.controls['arrival_time'].setValue(this.prefilledData.arrival_time);
				// To set day discount.
				this.ServiceProviderControl.controls['day_discount'].patchValue(this.dayDiscPrefilledPatch(this.prefilledData));
				if(!this.perDateAvailSpots || !this.perDateAvailSpots[this.selectedDate]){
					this.perDateAvailSpots[this.selectedDate] = [];
				}
				this.perDateAvailSpots[this.selectedDate].push(this.prefilledData.arrival_time);
			}
			this.ServiceProviderControl.controls['booking_date'].setValue(this.prefilledData.booking_date);
			let serviceProviderArray = <FormArray>this.ServiceProviderControl.controls['provider_details'];
			if(this.prefilledData.provider_details && (this.prefilledData.provider_details).length > 0){
				for(let provider of this.prefilledData.provider_details){
					serviceProviderArray.push(new FormControl(provider));
				}
			}
			this.checkIfPrefilledProvOrNotForQuote();
		}else{
			this.provider = null;
			this.resetProviderDetailsArray();
			this.resetProviderDetailsArray('pair');
			this.ServiceProviderControl.patchValue({
				arrival_date: null,
				arrival_time: null
			});
			this.selectedDate = null;
			this.ServiceProviderControl.controls['same_day_booking'].setValue(false);
		}
		/** event to call on date change **/
		this.onBookingDateChange.emit();
	}
	/**
	 * Prefilled the day discount data
	 * @param prefilledData
	 * @returns
	 */
	public dayDiscPrefilledPatch(prefilledData: any): any {
		return {
			discount : prefilledData.day_discount.discount ? prefilledData.day_discount.discount : null,
			discount_type : prefilledData.day_discount.discount_type ? prefilledData.day_discount.discount_type : 'fixed',
			apply_to_all : prefilledData.day_discount.apply_to_all ? prefilledData.day_discount.apply_to_all : 'true'
		}
	}
	/**
	 * Check if prefilled provider for quote or not.
	 */
	checkIfPrefilledProvOrNotForQuote(){
		if(!this.ServiceProviderControl.controls['provider_details'].value || (this.ServiceProviderControl.controls['provider_details'].value && (this.ServiceProviderControl.controls['provider_details'].value).length == 0)){
			this.ServiceProviderControl.controls['provider_type'].setValue('unassigned');
			this.ServiceProviderControl.controls['is_booking_unassigned'].setValue(true);
		}
		if(this.ServiceProviderControl.controls['provider_details'].value && (this.ServiceProviderControl.controls['provider_details'].value).length > 0){
			if(this.ServiceProviderControl.controls['provider_ids'].value){
				this.existingProviders = this.ServiceProviderControl.controls['provider_ids'].value;
			}
			//set same provider option prefilled by default.
			this.ServiceProviderControl.controls['provider_type'].setValue(this.prefilledData.provider_type);
			if(this.prefilledData?.non_available_provider){
				this.ServiceProviderControl.controls['non_available_provider'].setValue(this.prefilledData?.non_available_provider);
			}
			this.resetProviderChangeVar(this.prefilledData.provider_type);
			//check if provide id is 0 and set random provider option selected.
			this.setExistPrvdr();
		}else{
			if(this.prefilledData?.is_waiting_list){
				this.ServiceProviderControl.controls['is_waiting_list'].setValue(true);
				this.isWaitingListVal = this.ServiceProviderControl.controls['is_waiting_list'].value;
				this.resetProviderChangeVar('unassigned');
			}
		}
		this.onBookingDateChange.emit();
	}
	/**
	 * Function to prefilled data for reschedule
	 */
	// eslint-disable-next-line complexity
	prefilledRescData(): void{
		//get already booked or associated providers.
		this.preBookedProviders = this.prefilledData.provider_ids;
		this.bookingScheduledDay = this.prefilledData.day
		if(this.prefilledData && this.prefilledData.is_overlapped){
			this.currProviderSpotStatus = this.prefilledData.is_overlapped
		}else{
			this.currProviderSpotStatus = {};
		}
		//setup previous date and time details in fields to show prefilled data.
		this.existingProviderAvailability = this.ServiceProviderControl.controls['non_available_provider'].value;
		this.existingBookingDate = this.ServiceProviderControl.controls['booking_date'].value;
		this.existingArrivalDate = this.ServiceProviderControl.controls['arrival_date'].value;
		this.existingArrivalTime = this.ServiceProviderControl.controls['arrival_time'].value;
		if(this.existingArrivalTime){
			if(!this.perDateAvailSpots || !this.perDateAvailSpots[this.existingBookingDate]){
				this.perDateAvailSpots[this.existingBookingDate] = [];
			}
			this.perDateAvailSpots[this.existingBookingDate].push(this.existingArrivalTime);
		}
		this.ServiceProviderControl.controls['is_previous_day'].setValue(this.prefilledData.is_previous_day);
		if(this.ServiceProviderControl.controls['provider_details'].value && (this.ServiceProviderControl.controls['provider_details'].value).length > 0){
			if(this.ServiceProviderControl.controls['provider_ids'].value){
				this.existingProviders = this.ServiceProviderControl.controls['provider_ids'].value;
			}
			//set same provider option prefilled by default.
			this.ServiceProviderControl.controls['provider_type'].setValue('same_provider');
			this.resetProviderChangeVar('same_provider');
			//check if provide id is 0 and set random provider option selected.
			this.setExistPrvdr();
		}
		this.setupRecurringDates();
		setTimeout(() => {
			this.checkIfDateNeedToReset();
		},500)
		if(this.prefilledData.status == 9){
			this.ServiceProviderControl.controls['arrival_date'].setValue(null);
			this.ServiceProviderControl.controls['arrival_time'].setValue('');
			this.ServiceProviderControl.controls['booking_date'].setValue('');
			this.existingBookingDate = null;
			this.existingArrivalDate = null;
			this.selectedDate = null;
			this.resetProviderOnDateReset();
		}
		this.isSameProviderApplicable = this.checkSameProviderVisibility();
	}
	/**
	 * Function to set the existing provider for quote and reschedule
	 */
	setExistPrvdr(){
		let ifProviderAssociated = this.scheduleServ.checkIfProviderAssociated(this.existingProviders[0])
		if(!ifProviderAssociated && this.ServiceProviderControl.controls['is_waiting_list'].value){
			// this.ServiceProviderControl.controls['provider_type'].setValue('random_provider');
			this.ServiceProviderControl.controls['provider_type'].setValue('unassigned');
			this.resetProviderChangeVar('unassigned');
		}else if(!ifProviderAssociated){
			this.ServiceProviderControl.controls['is_waiting_list'].setValue(false);
			this.ServiceProviderControl.controls['provider_type'].setValue('random_provider');
			this.resetProviderChangeVar('random_provider');
		}
		let providers = this.ServiceProviderControl.controls['provider_details'].value;
		this.existingWagesReimbursements = providers;
		this.resetProviderDetailsArray();
		if(providers && providers.length > 0){
			for(let provider of providers){
				this.getExistingProvider(provider.id);
			}
			// Set the previous overriden time value for same provider
			this.overridePrvdrTimeServ.setPrvdrOverriddenTimeForSamePrvdr(this.ServiceProviderControl, this.prefilledData);
		}
	}
	/**
	 * Function to check if date need to reset in case of changes in booking params.
	 */
	// eslint-disable-next-line complexity
	checkIfDateNeedToReset(): void {
		if(!this.initServ.appAdmnStngs.merchant_settings?.providers?.scheduling_type || this.initServ.appAdmnStngs.merchant_settings?.providers?.scheduling_type != 'manually'){
			if((this.prefilledData.service_category != this.ServiceProviderControl.controls['service_category'].value ||
				this.prefilledData.frequency != this.ServiceProviderControl.controls['frequency'].value ||
				(this.prefilledData.length != this.ServiceProviderControl.controls['length'].value)) && this.prefilledData.status != 1 && this.prefilledData.status != 2 && this.prefilledData.status != 4){
				this.showRescheduleBtn = false;
				this.isReschedule = true;
				if(this.ServiceProviderControl.controls['provider_type'].value != 'unassigned' || (this.prefilledData.frequency != this.ServiceProviderControl.controls['frequency'].value)){
					this.ServiceProviderControl.controls['arrival_date'].setValue(null);
					this.ServiceProviderControl.controls['booking_date'].setValue('');
					this.selectedDate = null;
					this.resetProviderOnDateReset();
				}
			}
		}else{
			this.ServiceProviderControl.controls['provider_type'].setValue('unassigned');
			if((this.prefilledData.service_category != this.ServiceProviderControl.controls['service_category'].value || this.prefilledData.frequency != this.ServiceProviderControl.controls['frequency'].value ) && this.prefilledData.status != 1 && this.prefilledData.status != 2 && this.prefilledData.status != 4 ){
				this.showRescheduleBtn = false;
				this.isReschedule = true;
				this.ServiceProviderControl.controls['arrival_date'].setValue(null);
				this.ServiceProviderControl.controls['booking_date'].setValue('');
				this.selectedDate = null;
				this.resetProviderOnDateReset();
			}else{
				if(!this.selectedDate){
					this.arrival_date = this.setDateValue(this.existingBookingDate);
					this.selectedDate = this.existingBookingDate;
					this.ServiceProviderControl.controls['arrival_date'].setValue(this.arrival_date);
					this.ServiceProviderControl.controls['arrival_time'].setValue(this.existingArrivalTime);
					this.ServiceProviderControl.controls['booking_date'].setValue(this.existingBookingDate);
				}
			}
		}
		if(this.prefilledData && (this.prefilledData?.status == 9 || this.prefilledData?.status == 3)){
			this.showRescheduleBtn = false;
			this.isReschedule = true;
			this.ServiceProviderControl.controls['arrival_date'].setValue(null);
			this.ServiceProviderControl.controls['booking_date'].setValue('');
			this.selectedDate = null;
			this.resetProviderOnDateReset();
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Function to open the custom calendar popup.
	 */
	scheduleCalendarPopup(): void{
		this.bkngFormPopupServ.scheduleCalendarPopup(this.ServiceProviderControl,this.settings,this.selectedDate,this.dayDiscountBookings,this.dayDiscounts,this.dateDiscountsArray,this.dayDiscountsArray,this.prefilledData, this.isSpotAvailable, this.calSection, this.pageSett).pipe(takeUntil(this.destroy)).subscribe((res: any) => {
			if(res && res.data){
				this.isSpotAvailable = true;
				this.perDateAvailSpots = res.data.spots;
				this.ServiceProviderControl.controls['provider_skip_dates'].setValue(res?.data?.providerskipdates)
				this.availableDates = res.data.availableDates;
				this.notAvailableDates = res.data.notAvailableDates;
				this.selectedDateForSlot = res.data.providers;
				this.teamMembers = res.data.team_members;
				this.teamIds = res.data.teamIds;
				this.minProvidersReq = res.data.minprovidersreq
				let selectedDate = res.data.date.year + '-' + res.data.date.month + '-' + res.data.date.day;
				this.holidays = res.data.holidays
				this.clickMultiStepDate(selectedDate);
				if(res.slot){
					this.ServiceProviderControl.controls['arrival_time'].setValue(res.slot);
					if(res.slot !== null){
						this.partOfSlotChange(selectedDate, res.slot);
					}
					//function to set day discount.
					this.setDayDiscount(selectedDate, res.slot);
					this.onBookingDateChange.emit();
					this.onSpotChangeCallback.emit();
				}
				// Success merchant location selected
				// this.merchantLocSelect(res);
				// console.log('this.perDateAvailSpots',this.perDateAvailSpots)
			}
		});
	}
	/**
	 * Function to call on click of date in multi step layout.
	 */
	clickMultiStepDate(selectedDate: any): void{
		// this.multiStepSelectedDate = selectedDate;
		// this.multiStepSelectedSlot = null;
		this.onDateChange(selectedDate);
		// event to call on date change.
		this.onBookingDateChange.emit();
		// this.onDateChanged(arrivalDate, selectedDate);
	}
	/**
	 * Function to call on slot change.
	 * @param obj
	 */
	onSlotChangeFunc(obj: any): void{
		this.onDateChange(obj.slotDate);
		this.ServiceProviderControl.controls['arrival_time'].setValue(obj.slot);
		if(obj.slot !== null){
			this.partOfSlotChange(obj.slotDate, obj.slot);
		}
		//function to set day discount.
		this.setDayDiscount(obj.slotDate, obj.slot);
		//event to call on date change.
		this.onBookingDateChange.emit();
		this.onSpotChangeCallback.emit();
	}

	/**
	 * Function is part of function onSlotChange function.
	 */
	// eslint-disable-next-line complexity
	partOfSlotChange(selectedDate: any, slot: any): void {
		this.checkSameDayBooking(selectedDate);
		this.recurringDatesArray = this.scheduleServ.getRecurringDates(selectedDate, this.ServiceProviderControl.value.frequency_repeat_slug, this.scheduleRange,this.globals.totalBookingsCount, this.holidays, this.globals.skipHolidays);
		if(this.recurringDatesArray){
			this.recurringDates = this.recurringDatesArray.toString();
		}else{
			this.recurringDates = null;
		}
		this.ServiceProviderControl.controls['recurring_dates'].setValue(this.recurringDates);
		if(this.ServiceProviderControl.controls['provider_type'].value != 'specific_provider' && this.ServiceProviderControl.controls['provider_type'].value != 'same_provider'){
			if(this.selectedDateForSlot && this.selectedDateForSlot[selectedDate] && this.selectedDateForSlot[selectedDate][slot]){
				this.availableProviders = this.selectedDateForSlot[selectedDate][slot];
			}else{
				this.availableProviders = null;
			}
			this.resetProviderDetailsArray();
			this.resetProviderDetailsArray('pair');
			this.ServiceProviderControl.controls['non_available_provider'].setValue(false);
			if(this.initServ.appAdmnStngs.merchant_settings?.providers?.select_provider && this.initServ.appAdmnStngs.merchant_settings?.providers?.select_provider == 'manual'){
				this.ServiceProviderControl.controls['is_booking_unassigned'].setValue(true);
			}else if(this.ServiceProviderControl.controls['provider_type'].value == 'unassigned'){
				this.ServiceProviderControl.controls['is_booking_unassigned'].setValue(true);
			}else{
				if(this.availableProviders && this.utilServ.checkArrLength(this.availableProviders)){
					this.getProvider(this.availableProviders[0]);
					// if(this.minProvidersReq > 1 && (typeof(this.teamMembers) == 'undefined' || typeof(this.teamMembers[+this.availableProviders[0]]) == 'undefined')){
					if(this.minProvidersReq > 1 &&  this.teamIds && !this.teamIds.includes(this.availableProviders[0])){
						let i = 1
						let j = 1
						// eslint-disable-next-line max-depth
						while(i<this.minProvidersReq){
							// eslint-disable-next-line max-depth
							if(!this.teamIds.includes(this.availableProviders[j])){
								this.getProvider(this.availableProviders[j],'pair');
								i++;
							}
							j++;
						}
					}
				}else{
					// We introduce this block to handle a live issue like, some time customer book from customer end and booking goes in unassigned folder, but settings didnt allow that, that because of failing response of this api, so we handle this case to reset date time if no provider available.
					this.resetDateTime();
					// Bugsnag added
					Bugsnag.notify(new Error('availableProviders array is empty:: '+JSON.stringify(this.availableProviders)));
				}
			}
		}
	}
	/**
	 * Function to check same day booking.
	 */
	checkSameDayBooking(date: any): void{
		let currentDate = new Date();
		let currDateString = this.scheduleServ.getDateYMD(currentDate)
		if(this.globals.allowSameDayBooking == 'no'){
			this.ServiceProviderControl.controls['same_day_booking'].setValue(false);
		}else if(currDateString == date ){
			this.ServiceProviderControl.controls['same_day_booking'].setValue(true);
		}else{
			this.ServiceProviderControl.controls['same_day_booking'].setValue(false);
		}
	}
	/**
	* Function called on selection of available slot.
	* Get Provider for booking randomaly.
	*/
	getProvider(providerId: any,type: string = 'individual'): void{
		this.loader.show(this.provLoaderId);
		let queryParams: any = {industry_id:this.ServiceProviderControl.controls['industry_id'].value,form_id:this.ServiceProviderControl.controls['form_id'].value,location_type:this.ServiceProviderControl.controls['location_type'].value};
		this.apiServ.callApiWithPathQueryVars('GET','getProvider', [providerId], queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.checkProviderApiResponse(res, type));
	}
	/**
	 * Function called on succes of getProvider function.
	 * Stored data in local variable.
	 */
	checkProviderApiResponse(res: any,type: string): void {
		if (this.apiServ.checkAPIRes(res) && res?.data) {
			let provider= this.scheduleServ.checkExistingWages(res.data, this.existingWagesReimbursements);
			if(type == 'individual'){
				this.provider = provider;
			}
			let serviceProviderArray = <FormArray>this.ServiceProviderControl.controls['provider_details'];
			if(!(this.ServiceProviderControl.controls['provider_ids'].value).includes(provider.id)){
				serviceProviderArray.push(new FormControl(provider));
				let pairsArray = <FormArray>this.ServiceProviderControl.controls['pair_availability'];
				let newPair = { id: provider.id, availability: true };
				pairsArray.push(new FormControl(newPair));
			}
			// Reset provider overriden time
			this.resetPrvdrOverriddenTime.emit();
		}else{
			// We introduce this block to handle a live issue like, some time customer book from customer end and booking goes in unassigned folder, but settings didnt allow that, that because of failing response of this api, so we handle this case to reset date time if no provider available.
			this.resetDateTime();
			// Bugsnag added
			try {
				Bugsnag.notify(new Error('Get provider api response is empty:: '+JSON.stringify(res)));
			} catch (error) { /* empty */ }
		}
		this.loader.hide(this.provLoaderId);
		this.reloadProviderLayout()
	}
	/**
	 * Function to get the selected provider details
	 * @param provider
	 */
	getSelectedProvider(provider: any): void {
		if(provider) {
			this.loader.show(this.provLoaderId);
			this.ServiceProviderControl.controls['non_available_provider'].setValue(false);
			let queryParams: any = {industry_id:this.ServiceProviderControl.controls['industry_id'].value,form_id:this.ServiceProviderControl.controls['form_id'].value,location_type:this.ServiceProviderControl.controls['location_type'].value};
			this.apiServ.callApiWithPathQueryVars('GET','getProvider', [provider.id], queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.checkSelectedProviderApiResponse(res));
		}
	}
	/**
	* Function called on succes of getProvider function.
	* Stored data in local variable.
	*/
	checkSelectedProviderApiResponse(response:any): void{
		if(this.apiServ.checkAPIRes(response)){
			this.ServiceProviderControl.controls['arrival_date'].setValue('');
			this.ServiceProviderControl.controls['booking_date'].setValue('');
			this.ServiceProviderControl.controls['arrival_time'].setValue(null);
			this.selectedDate = null;
			this.provider= this.scheduleServ.checkExistingWages(response.data, this.existingWagesReimbursements);
			//Reset pair_availability
			this.resetProviderDetailsArray('pair');
			let pairsArray = <FormArray>this.ServiceProviderControl.controls['pair_availability'];
			let newPair = { id : this.provider.id, availability: true};
			pairsArray.push(new FormControl(newPair));
			this.resetProviderDetailsArray();
			// reassign new provider
			let serviceProviderArray = <FormArray>this.ServiceProviderControl.controls['provider_details'];
			serviceProviderArray.push(new FormControl(this.provider))
			// this.providers = this.provider;
		}
		// console.log(2222,this.ServiceProviderControl.controls['provider_details'].value)
		// Reset provider overriden time
		this.resetPrvdrOverriddenTime.emit();
		this.reloadProviderLayout();
		this.loader.hide(this.provLoaderId);
	}

	/**
	 * Function called on selection of available slot.
	 * @param providerId
	 */
	getExistingProvider(providerId: any): void{
		this.loader.show(this.provLoaderId);
		let queryParams: any = {industry_id:this.ServiceProviderControl.controls['industry_id'].value,form_id:this.ServiceProviderControl.controls['form_id'].value,location_type:this.ServiceProviderControl.controls['location_type'].value};
		this.apiServ.callApiWithPathQueryVars('GET','getProvider', [providerId], queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.checkExisProvApiResp(res));
	}
	/**
	* Function called on succes of getProvider function.
	* Stored data in local variable.
	*/
	checkExisProvApiResp(res: any): void{
		if(this.apiServ.checkAPIRes(res)){
			let sameProvider= res.data;
			if(sameProvider){
				if(sameProvider.turn_off_booking == 'yes'){
					this.isCurrentProviderBlock = true;
				}
				/** Function to check existing wages of provider for this booking. **/
				sameProvider = this.scheduleServ.checkExistingWages(sameProvider, this.existingWagesReimbursements);
				(this.sameProvider).push(sameProvider);
				this.setProviderSelectOptions();
				this.selectExistingProvider(sameProvider);
			}
			this.cDRef.detectChanges();
		}
		this.loader.hide(this.provLoaderId);
	}
	/**
	 * Function to select existing provider.
	 */
	selectExistingProvider(newProvider: any): void{
		if(newProvider){
			let serviceProviderArray = <FormArray>this.ServiceProviderControl.controls['provider_details'];
			serviceProviderArray.push(new FormControl(newProvider));
		}
	}
	/**
	 * Function to set schedule option for customer like if can choose specific, can select waiting list option etc
	 */
	setProviderSelectOptions(): void{
		// Set schedule option for customer like if can choose specific, can select waiting list option etc.
		this.customerScheduleOptions['sameProvider'] = ((this.sameProvider && (this.sameProvider).length > 0) && this.checkSameProviderVisibility() && this.checkSameProviderForUnassigned() && !this.isCurrentProviderBlock && this.prefilledData.status != 9);
		this.customerScheduleOptions['specificProvider'] = this.scheduleServ.canCustomerSelectSpecificProvider(this.admnStngs)
		this.customerScheduleOptions['previousProvider'] = this.scheduleServ.checkVisibilityOfPreviousSelected(this.previousProviders,this.availableProviderIds)
		this.customerScheduleOptions['waitingList'] = this.scheduleServ.canCustomerJoinWaitingList(this.admnStngs)
	}
	/**
	 * Function to save the booking
	 */
	getAllBookingSpots(): void{
		let location_id = this.ServiceProviderControl.controls['base_location_id'].value;
		let queryParams: any = {location_id: location_id};
		this.apiServ.callApiWithQueryParams('GET', 'BookingSpots', queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.checkAllSpotsResponse(res));
	}
	/*
	 * Function to check the response of getAllAvailableProviders api.
	 */
	checkAllSpotsResponse(response: any): void{
		if(response.api_status == 1){
			this.allBookingSpots = response.data;
			this.isAllDataLoaded = true;
		}
	}
	/**
	 * Function to set day discount.
	 */
	setDayDiscount(selectedDate: any, slot: any): void{
		let prefilledSpotDiscount = JSON.stringify(this.ServiceProviderControl.controls['day_discount'].value);
		this.ServiceProviderControl.controls.day_discount.patchValue({
			discount : null,
			discount_type : 'fixed',
			apply_to_all : 'true'
		});
		if(this.dayDiscounts && (this.dayDiscounts).length > 0){
			let dayDiscount = this.scheduleServ.showSlotDiscount(selectedDate, slot, this.dateDiscountsArray, this.dayDiscountsArray, this.dayDiscountBookings, this.prefilledData, "slot");
			if(dayDiscount){
				this.ServiceProviderControl.controls.day_discount.patchValue({
					discount : dayDiscount.discount,
					discount_type : dayDiscount.discount_type,
					apply_to_all : this.dayDiscountApplyToAll
				});
			}
		}
		let currentSpotDiscount = JSON.stringify(this.ServiceProviderControl.controls['day_discount'].value);
		this.OnSpotDiscRemove(prefilledSpotDiscount, currentSpotDiscount);
	}
	OnSpotDiscRemove(prefilledSpotDiscount: string, currentSpotDiscount: string){
		if(JSON.stringify(prefilledSpotDiscount) !== JSON.stringify(currentSpotDiscount)){
			let dayDiscGroup = <FormGroup>this.ServiceProviderControl?.controls['day_discount'];
			if(!dayDiscGroup?.controls['discount']?.value && !this.prefilledData?.exclude_day_discount){
				let msg: string = 'Spot discount removed due to changes in schedule';
				this.bkngFormServ.newMsgPopup(msg);
			}
		}
	}
	/**
	 * Function to call on date change.
	 * @param selectedDate
	 */
	onDateChange(selectedDate: any): void{
		let arrivalDate = this.setDateValue(selectedDate);
		this.selectedDate = selectedDate;
		this.ServiceProviderControl.controls['booking_date'].setValue(selectedDate);
		this.ServiceProviderControl.controls['arrival_date'].setValue(arrivalDate);
		this.ServiceProviderControl.controls['arrival_time'].setValue('');
	}
	/**
	 * Function to call on provider change checkboxes.
	 */
	onProviderTypeChangePart(event:any, providerType:any): void {
		this.isMultiCalReload = false; // For refresh the multi step calender
		this.selectedScheduleOptions['isSameProvider'] = false;
		this.selectedScheduleOptions['isSpecificProvider'] = false;
		this.selectedScheduleOptions['isUnassignedProvider'] = false;
		this.selectedScheduleOptions['isPreviousProvider'] = false;
		this.ServiceProviderControl.controls['is_waiting_list'].setValue(false);
		if(providerType == 'unassigned'){
			this.ServiceProviderControl.controls['is_waiting_list'].setValue(true);
		}
		if(event.target.checked){
			this.ServiceProviderControl.controls['provider_type'].setValue(providerType);
			this.resetProviderChangeVar(providerType);
			if(this.bookingType == 'reschedule'){
				this.onProviderTypeChange(providerType);
			}
		}else{
			this.ServiceProviderControl.controls['provider_type'].setValue('random_provider');
			if(this.bookingType == 'reschedule'){
				this.onProviderTypeChange('random_provider');
			}
		}
		if(this.bookingType != 'reschedule'){
			this.onProviderTypeChange();
		}
		// For refresh the multi step calender
		setTimeout(() => {
			this.isMultiCalReload = true;
			this.cDRef.detectChanges();
		}, 0);
	}
	/**
	 * Function to reset the value of provider change variables.
	 */
	resetProviderChangeVar(providerType: any): void{
		this.selectedScheduleOptions['isSameProvider'] = false;
		this.selectedScheduleOptions['isSpecificProvider'] = false;
		this.selectedScheduleOptions['isUnassignedProvider'] = false;
		this.selectedScheduleOptions['isPreviousProvider'] = false;
		if(providerType == 'same_provider'){
			this.selectedScheduleOptions['isSameProvider'] = true;
		}else if(providerType == 'specific_provider'){
			this.selectedScheduleOptions['isSpecificProvider'] = true;
		}else if(providerType == 'previous_provider'){
			this.selectedScheduleOptions['isPreviousProvider'] = true;
		}else if(providerType != 'random_provider'){
			this.selectedScheduleOptions['isUnassignedProvider'] = true;
		}
	}
	/**
	* Function called on provider type change.
	* Reset the form values.
	*/
	// eslint-disable-next-line complexity
	onProviderTypeChange(providerType : string = ''): void {
		this.ServiceProviderControl.controls['non_available_provider'].setValue(false);
		this.ServiceProviderControl.controls['arrival_date'].setValue('');
		this.ServiceProviderControl.controls['arrival_time'].setValue('');
		this.ServiceProviderControl.controls['booking_date'].setValue('');
		this.selectedDate = null;
		// this.multiStepSelectedDate = '';
		// this.multiStepSelectedSlot = '';
		this.selectedDateForSlot = null;
		this.ServiceProviderControl.controls['arrival_date'].markAsUntouched();
		this.ServiceProviderControl.controls['arrival_time'].markAsUntouched();
		this.provider = '';
		// this.selectedPreviousProvider = null;
		this.resetProviderDetailsArray();
		this.resetProviderDetailsArray('pair');
		if(providerType == 'same_provider'){
			if(this.sameProvider && (this.sameProvider).length > 0){
				for(let provider of this.sameProvider){
					this.selectExistingProvider(provider);
				}
			}
			if(this.showRescheduleBtn){
				if(this.existingArrivalDate){
					let localtimestamp = this.utilServ.dateObj(this.existingBookingDate);
					this.arrival_date = {jsdate: localtimestamp};
				}
				this.ServiceProviderControl.controls['arrival_date'].setValue(this.arrival_date);
				// TODO:
				// if(this.availableSlots.includes((this.existingArrivalTime).toString())){
				if(this.existingArrivalTime){
					this.ServiceProviderControl.controls['arrival_time'].setValue(this.existingArrivalTime);
				}else{
					this.ServiceProviderControl.controls['arrival_time'].setValue(null)
				}
				this.selectedDate = this.existingBookingDate;
				this.ServiceProviderControl.controls['booking_date'].setValue(this.existingBookingDate);
				if(this.existingBookingDate){
					this.setDayDiscount(this.existingBookingDate, this.existingArrivalTime);
				}
			}
			// Set the previous overriden time value for same provider
			this.overridePrvdrTimeServ.setPrvdrOverriddenTimeForSamePrvdr(this.ServiceProviderControl, this.prefilledData);
		}
		this.ServiceProviderControl.controls['is_booking_unassigned'].setValue(false);
		if(this.bookingType == 'reschedule' &&  providerType == 'unassigned'){
			if(this.prefilledData.status != 9){
				let tomorrowDateObj = new Date();
				tomorrowDateObj.setDate(tomorrowDateObj.getDate()+1);
				if(this.existingArrivalDate){
					let localtimestamp = this.utilServ.dateObj(this.existingBookingDate);
					this.arrival_date = {jsdate: localtimestamp};
				}
				this.ServiceProviderControl.controls['arrival_date'].setValue(this.arrival_date);
				this.ServiceProviderControl.controls['arrival_time'].setValue(this.existingArrivalTime);
				this.ServiceProviderControl.controls['booking_date'].setValue(this.existingBookingDate);
				this.selectedDate = this.existingBookingDate;
				if(this.existingBookingDate){
					this.setDayDiscount(this.existingBookingDate, this.existingArrivalTime);
				}
			}
			this.ServiceProviderControl.controls['non_available_provider'].setValue(this.existingProviderAvailability);
			this.ServiceProviderControl.controls['is_booking_unassigned'].setValue(true);
			this.ServiceProviderControl.controls['is_provider_reschedule'].setValue(false);
			this.ServiceProviderControl.controls['automatically_accept_job'].setValue(false);
		}
		// reset the priority of booking
		this.ServiceProviderControl.controls['booking_priority'].setValue('');
		if(this.ServiceProviderControl.controls['provider_type'].value == 'unassigned'){
			this.ServiceProviderControl.controls['booking_priority'].setValue('low');
		}
	}
	/**
	 * Function call on waiting list change.
	 */
	onWaitListChng(event: any): void{
		if(event.target.checked){
			this.ServiceProviderControl.controls['is_waiting_list'].setValue(true);
		}else{
			this.ServiceProviderControl.controls['is_waiting_list'].setValue(false);
		}
	}
	/**
	 * Function to reset the provider on date reset.
	 */
	resetProviderOnDateReset(): void {
		if (this.ServiceProviderControl.controls['provider_type'].value == 'random_provider') {
			this.resetProviderDetailsArray();
			this.resetProviderDetailsArray('pair');
			this.ServiceProviderControl.controls['non_available_provider'].setValue(false);
		}
	}
	/**
	 * Function to reset the providers and pairs
	 * @param type
	 */
	resetProviderDetailsArray(type : string = 'provider_details'): void{
		let controlNameArray = 'provider_details';
		if(type == 'pair'){
			controlNameArray = 'pair_availability';
		}
		let serviceProviderArray = <FormArray>this.ServiceProviderControl.controls[controlNameArray];
		let providers = this.ServiceProviderControl.controls[controlNameArray].value;
		if(providers && providers.length > 0){
			for(let provider of providers){
				let index = serviceProviderArray.controls.findIndex(x => x.value.id == provider.id);
				serviceProviderArray.removeAt(index);
			}
		}
	}
	/**
	 * Function to prepare the data required to filter available providers which will be used by other functions to
	 * get the available/applicable providers list
	 */
	prepareAvailProviderFilters(): void{
		this.availProvFilters = null;
		this.availProvFilters = this.scheduleServ.availProviderFilters(this.ServiceProviderControl.value);
	}
	/**
	 * Function to set the calendar date value.
	 */
	setDateValueCalendar(): any {
		if(this.ServiceProviderControl.controls['booking_date'].value){
			this.formattedDate = this.dateServ.formattedDateFromString(this.ServiceProviderControl.controls['booking_date'].value,true,this.admnStngs.merchant_settings?.store?.date_format)
		}else{
			this.formattedDate = '';
		}
		return this.formattedDate
	}
	/**
	 * Function to check if provider display.
	 */
	isProviderDisplayOn(){
		if(this.admnStngs.merchant_settings?.customers?.enable_provider_display_to_customer == 'yes'){
			if ((this.admnStngs.merchant_settings?.customers?.provider_display_on == 'both') || (this.admnStngs.merchant_settings?.customers?.provider_display_on == 'backend' && (this.currentUser && this.currentUser.token)) || (this.admnStngs.merchant_settings?.customers?.provider_display_on == 'frontend' && !this.currentUser)) {
				this.showSelectedProvider = true;
			}
		}
	}
	/**
	 * Function to reload the provider layout.
	 */
	reloadProviderLayout(){
		this.reloadProvider = false;
		setTimeout(()=>{
			this.reloadProvider = true;
			this.cDRef.detectChanges();
		},1);
	}
	/**
	 * Reset the date and time.
	 */
	resetDateTime(): void{
		// this.availableSlots = null;
		this.provider = null;
		this.resetProviderDetailsArray();
		this.resetProviderDetailsArray('pair');
		this.ServiceProviderControl.patchValue({
			arrival_date : null,
			arrival_time : null,
			booking_date : null
		});
		this.selectedDate = null;
		// this.multiStepSelectedDate = null;
		// this.multiStepSelectedSlot = null;
		this.ServiceProviderControl.controls['same_day_booking'].setValue(false);
		this.onBookingDateChange.emit();
	}
	/**
	 * Function used to reset date time in case of frequency change for single form.
	 */
	reSelectDateTime(): void {
		// this.showRescheduleBtn = false;
		// this.isReschedule = true;
		this.ServiceProviderControl.controls['arrival_date'].setValue(null);
		this.ServiceProviderControl.controls['booking_date'].setValue(null);
		this.selectedDate = null;
		this.resetProviderOnDateReset();
	}
	/**
	 * Function to reset the recurring dates according to coupon precharge settings.
	 * @param couponData
	 */
	resetRecurringDates(couponData: any): void {
		let bookingDate = this.ServiceProviderControl.controls['booking_date'].value;
		this.scheduleRange = this.scheduleServ.getScheduleRange()
		if(couponData && couponData.prepay_rec_booking && couponData.prepay_rec_booking == 'yes' && couponData.prepay_bookings_count && (+this.globals.totalBookingsCount <= +couponData.prepay_bookings_count || (this.recurringDatesArray && (this.recurringDatesArray).length <= +couponData.prepay_bookings_count))){
			if(this.checkAvailabilityFor == 'all'){
				/** Note: for this case for now we reset the recurring dates array.
				          But it create problem like if provider not available for extra bookings that added because of
				          precharge count.
				          To handle this case vikram sir look into this.
				**/
				this.globals.totalBookingsCount = couponData.prepay_bookings_count;
				this.scheduleRange = 1800;
				this.resetRecurringArray(bookingDate);
			}else{
				this.globals.totalBookingsCount = couponData.prepay_bookings_count;
				this.scheduleRange = 1800;
				this.resetRecurringArray(bookingDate);
			}
		}else{
			this.resetRecurringArray(bookingDate);
		}
	}
	/**
	 * Function to reset the recurring dates array.
	 * @param bookingDate
	 */
	resetRecurringArray(bookingDate: any): void{
		if(bookingDate){
			let frequencyOccurance = this.ServiceProviderControl.controls['frequency_repeat_slug'].value;
			this.ServiceProviderControl.controls['recurring_dates'].setValue(null);
			this.recurringDates = null
			this.recurringDatesArray = this.scheduleServ.getRecurringDates(bookingDate, frequencyOccurance, this.scheduleRange, this.globals.totalBookingsCount,this.holidays, this.globals.skipHolidays);
			// console.log(4444,this.recurringDatesArray)
			if(this.recurringDatesArray){
				this.recurringDates = this.recurringDatesArray.toString();
			}else{
				this.recurringDates = null;
			}
			// console.log('this.recurringDates 44',this.globals.totalBookingsCount, this.recurringDates)
			this.ServiceProviderControl.controls['recurring_dates'].setValue(this.recurringDates);
		}
	}
	/**
	 * Function to call on booking parameter change.
	 */
	// eslint-disable-next-line complexity
	onBookingParameterChange(): void{
		if(this.ServiceProviderControl.controls['arrival_date'].value){
			let flag = 0;
			if(this.prefilledData.service_category != this.ServiceProviderControl.controls['service_category'].value ||
				this.prefilledData.frequency != this.ServiceProviderControl.controls['frequency'].value ||
				( this.prefilledData.length != this.ServiceProviderControl.controls['length'].value)){
				flag = 1;
				if(this.ServiceProviderControl.controls['provider_type'].value != 'unassigned' || (this.prefilledData.frequency != this.ServiceProviderControl.controls['frequency'].value)){
					this.ServiceProviderControl.controls['arrival_date'].setValue(null);
					this.ServiceProviderControl.controls['booking_date'].setValue('');
					this.selectedDate = null;
					this.resetProviderOnDateReset();
					this.onBookingDateChange.emit();
				}
			}else if((this.prefilledData.adjust_time != this.ServiceProviderControl.controls['adjust_time'].value) || (this.prefilledData.adjust_time &&
				this.ServiceProviderControl.controls['adjust_time'].value && (this.prefilledData.adjusted_time != this.ServiceProviderControl.controls['adjusted_time'].value))){
				flag = 1;
				this.ServiceProviderControl.controls['arrival_date'].setValue(null);
				this.ServiceProviderControl.controls['booking_date'].setValue('');
				this.selectedDate = null;
				this.resetProviderOnDateReset();
				this.onBookingDateChange.emit();
			}
			if(flag){
				// TODO
				this.showRescheduleBtn = false;
				this.isReschedule = true;
			}
		}
	}
	/**
	 * Function to setup recurring dates.
	 */
	// eslint-disable-next-line complexity
	setupRecurringDates(): void{
		// Deafult value of apply to all accroding to store settings
		let defValOfApplyToAll = this.scheduleServ.defValOfApplyToAllFromStoreSett();

		if(this.ServiceProviderControl.controls['frequency_repeat_slug'].value == 'onetime'){
			this.ServiceProviderControl.controls['apply_to_all'].setValue("false");
		}else{
			this.ServiceProviderControl.controls['apply_to_all'].setValue(defValOfApplyToAll);
		}
		if(this.prefilledData.frequency != this.ServiceProviderControl.controls['frequency'].value &&
			(this.prefilledData.status == 6 || this.prefilledData.status == 7 || this.prefilledData.status == 8 )
			){
			this.ServiceProviderControl.controls['apply_to_all'].setValue("true");
		}
		if(this.prefilledData && this.prefilledData.status == 4){
			this.ServiceProviderControl.controls['apply_to_all'].setValue("false");
		}
		this.recurringDatesArray = this.scheduleServ.getRecurringDates(this.existingBookingDate, this.ServiceProviderControl.controls['frequency_repeat_slug'].value, this.scheduleRange, this.globals.totalBookingsCount,this.holidays, this.globals.skipHolidays);
		if(this.recurringDatesArray){
			this.recurringDates = this.recurringDatesArray.toString();
		}else{
			this.recurringDates = null;
		}
		// console.log('this.recurringDates 11',this.globals.totalBookingsCount, this.recurringDates)
		this.ServiceProviderControl.controls['recurring_dates'].setValue(this.recurringDates);
	}
	/**
	 * Function to check the visibility of same provider visibility.
	 */
	// eslint-disable-next-line complexity
	checkSameProviderVisibility(): boolean{
		let flag = 0;
		if(this.sameProvider && (this.sameProvider).length > 0){
			for(let provider of this.sameProvider){
				if(this.availableProviderIds && (this.availableProviderIds).length > 0 && !(this.availableProviderIds).includes(provider.id)){
					flag = 1;
				}
			}
		}
		if(this.prefilledData.status == 9 && this.showRescheduleBtn != false){
			flag = 1;
		}
		if(flag == 1){
			if(this.ServiceProviderControl.controls['provider_type'].value == 'same_provider' || this.prefilledData.status == 9){
				this.ServiceProviderControl.controls['provider_type'].setValue('random_provider');
				this.resetProviderChangeVar('random_provider');
				this.ServiceProviderControl.controls['is_provider_reschedule'].setValue(true);
				this.ServiceProviderControl.controls['non_available_provider'].setValue(false);
				this.ServiceProviderControl.controls['booking_date'].setValue(null);
				this.ServiceProviderControl.controls['arrival_date'].setValue(null);
				this.ServiceProviderControl.controls['arrival_time'].setValue(null);
				this.selectedDate = null;
				this.isReschedule = true;
				this.resetProviderDetailsArray('pair');
				this.resetProviderDetailsArray();
				this.showRescheduleBtn = false;
			}
			this.isSameProviderApplicable = false
			return false;
		}
		this.isSameProviderApplicable = true
		return true;
	}
	/**
	* Function to check the visibility of apply to all button visibiulity.
	*/
	// eslint-disable-next-line complexity
	checkRecurringButtonVisibility(): boolean {
		if(this.prefilledData){
			if(this.prefilledData.is_previous_day && (this.prefilledData.status != 6 && this.prefilledData.status != 7 && this.prefilledData.status != 8)){
				this.ServiceProviderControl.controls['apply_to_all'].setValue("false");
				this.ServiceProviderControl.controls['is_first'].setValue(0);
				return false;
			}else if((this.prefilledData.status == 6 || this.prefilledData.status == 7 || this.prefilledData.status == 8)){
				if(this.prefilledData.apply_to_all && this.prefilledData.apply_to_all == 'false'){
					return true;
				}else{
					this.ServiceProviderControl.controls['apply_to_all'].setValue("true");
					return false;
				}
			}else if(this.prefilledData.frequency != this.ServiceProviderControl.controls['frequency'].value){
				this.ServiceProviderControl.controls['apply_to_all'].setValue("true");
				return false;
			}else if(this.prefilledData.status == 3){
				// Comment this code ref:swati mam
				// if(!this.prefilledData.is_confirmed && this.prefilledData.is_first == 1){
				// 	this.ServiceProviderControl.controls['apply_to_all'].setValue("true");
				// }else if(this.prefilledData.apply_to_all == false){
				// 	this.ServiceProviderControl.controls['apply_to_all'].setValue("false");
				// }else{
				// 	this.ServiceProviderControl.controls['apply_to_all'].setValue("true");
				// }
				return true
			}else if(this.prefilledData.status == 9){
				this.ServiceProviderControl.controls['apply_to_all'].setValue("true");
				return false;
			}
			return true;
		}
		return true;
	}
	/**
	 * Function to check same provider for unassigned.
	 */
	checkSameProviderForUnassigned(): boolean{
		if(this.prefilledData && this.prefilledData.status == 7){
			if(this.ServiceProviderControl.controls['provider_type'].value == 'same_provider'){
				this.ServiceProviderControl.controls['is_provider_reschedule'].setValue(true);
				this.ServiceProviderControl.controls['non_available_provider'].setValue(false);
			}
			return false
		}
		return true
	}
	/**
	* Function to hide/show reschedule section
	*/
	// eslint-disable-next-line complexity, max-lines-per-function
	ShowRescheduleSection(resetDate: boolean): void{
		let realProviderType = 'same_provider';
		if(!this.isReschedule){
			realProviderType = this.ServiceProviderControl.controls['provider_type'].value;
		}
		this.ServiceProviderControl.controls['non_available_provider'].setValue(false);
		this.ServiceProviderControl.controls['is_provider_reschedule'].setValue(true);

		// this.setupOtherDates(this.existingBookingDate)
		this.arrival_date = this.setDateValue(this.existingBookingDate);
		// eslint-disable-next-line no-var
		var providers = this.ServiceProviderControl.controls['provider_details'].value;
		if(this.ServiceProviderControl.controls['provider_type'].value == 'same_provider' || this.ServiceProviderControl.controls['provider_type'].value == 'unassigned'){
			/** set the default date and time for same provider **/
			if(providers.length > 0){
				// eslint-disable-next-line no-var
				var providersarray :any = [];
				for(let provider of providers){
					providersarray.push(provider.id)
				}
			}
			else{
				// eslint-disable-next-line no-var
				var providersarray =  this.availableProviders;
			}
			// let all_dates = this.recurringDatesArray.toString()+","+this.nextRecurringDatesArray.toString()+","+this.nextNextRecurringDatesArray.toString();
			// if(this.checkAvailabilityFor == 'first'){
			// 	all_dates = this.existingBookingDate+","+this.availableNextSlotDate+","+this.availableNextNextSlotDate
			// 	this.allDatesArray = [this.existingBookingDate,this.availableNextSlotDate,this.availableNextNextSlotDate]
			// }
			// else{
			// 	this.allDatesArray = this.recurringDatesArray.concat(this.nextRecurringDatesArray);
			// 	this.allDatesArray = this.allDatesArray.concat(this.nextNextRecurringDatesArray)
			// }
			// this.getHolidays()
			// this.getProvidersData(providersarray,all_dates,this.existingBookingDate)
			if(this.existingArrivalDate){
				this.arrival_date = this.setDateValue(this.existingBookingDate);
			}
			this.ServiceProviderControl.controls['arrival_date'].setValue(this.arrival_date);
			this.ServiceProviderControl.controls['arrival_time'].setValue(this.existingArrivalTime);
			this.ServiceProviderControl.controls['booking_date'].setValue(this.existingBookingDate);
			this.selectedDate = this.existingBookingDate;
		}
		else if(this.ServiceProviderControl.controls['provider_type'].value == 'random_provider' && (!providers || (providers && providers.length == 0))){
			if(this.existingArrivalDate){
				this.arrival_date = this.setDateValue(this.existingBookingDate);
			}
			this.ServiceProviderControl.controls['arrival_date'].setValue(this.arrival_date);
			this.ServiceProviderControl.controls['arrival_time'].setValue(this.existingArrivalTime);
			this.ServiceProviderControl.controls['booking_date'].setValue(this.existingBookingDate);
			this.selectedDate = this.existingBookingDate;
		}
		else{
			this.ServiceProviderControl.controls['booking_date'].setValue(null);
			this.ServiceProviderControl.controls['arrival_date'].setValue(null);
			this.ServiceProviderControl.controls['arrival_time'].setValue(null);
			this.selectedDate = null;
			this.resetProviderOnDateReset();
		}
		this.isReschedule = !this.isReschedule;
		if(this.isReschedule && this.isCurrentProviderBlock){
			this.ServiceProviderControl.controls['provider_type'].setValue('random_provider');
			this.resetProviderChangeVar('random_provider');
			this.ServiceProviderControl.controls['arrival_date'].setValue(null);
			this.ServiceProviderControl.controls['arrival_time'].setValue('');
			this.ServiceProviderControl.controls['booking_date'].setValue(null);
			this.selectedDate = null;
			this.resetProviderDetailsArray('pair');
			this.resetProviderDetailsArray();
		}

		if(!this.isReschedule){
			this.resetProviderDetailsArray('pair');
			this.resetProviderDetailsArray();
			if(this.sameProvider && (this.sameProvider).length > 0){
				for(let provider of this.sameProvider){
					this.selectExistingProvider(provider);
				}
			}
			this.ServiceProviderControl.controls['non_available_provider'].setValue(this.existingProviderAvailability);
			this.selectedDate = this.existingBookingDate;
			this.ServiceProviderControl.controls['booking_date'].setValue(this.existingBookingDate);
			this.ServiceProviderControl.controls['arrival_date'].setValue(this.existingArrivalDate);
			this.ServiceProviderControl.controls['arrival_time'].setValue(this.existingArrivalTime);
			this.ServiceProviderControl.controls['is_provider_reschedule'].setValue(false);
			if(parseInt(this.prefilledData.provider_ids[0]) == 0){
				if(this.admnStngs.merchant_settings?.bookings?.show_waiting_list && this.admnStngs.merchant_settings?.bookings?.show_waiting_list == 'yes'){
					this.ServiceProviderControl.controls['provider_type'].setValue('unassigned');
					this.resetProviderChangeVar('unassigned');
				}else{
					this.ServiceProviderControl.controls['provider_type'].setValue('random_provider');
					this.resetProviderChangeVar('random_provider');
				}
			}else{
				this.ServiceProviderControl.controls['provider_type'].setValue(realProviderType);
				this.resetProviderChangeVar(realProviderType);
			}
		}
		if(resetDate){
			this.ServiceProviderControl.controls['booking_date'].setValue(null);
			this.ServiceProviderControl.controls['arrival_date'].setValue(null);
			this.ServiceProviderControl.controls['arrival_time'].setValue(null);
			this.selectedDate = null;
			this.resetProviderOnDateReset();
		}
	}
	/**
	 * Function to call on change of apply type.
	 */
	changeApplyType(): void{
		if(this.isReschedule){
			this.ServiceProviderControl.controls['booking_date'].setValue(null);
			this.ServiceProviderControl.controls['arrival_date'].setValue(null);
			this.ServiceProviderControl.controls['arrival_time'].setValue("");
			this.selectedDate = null;
			this.resetProviderOnDateReset();
		}
	}
	/**
	 *
	 * @param date Function to set the date value.
	 * @returns
	 */
	setDateValue(date: any): any{
		let localtimestamp = this.utilServ.dateObj(date);
		let epocData = localtimestamp.getTime();
		epocData = epocData/1000;
		return {
			jsdate: localtimestamp,
			epoc: epocData
		}
	}
}
