import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
// Popup components
import { PicturePreviewPopupComponent, MerchantLocationsPopupComponent, PackagesPopupComponent, ScheduleCalendarPopupComponent, ViewProviderReviewsPopupComponent } from './index'
import { HowItWorksPopupComponent } from './Sidebar';

@Injectable({
	providedIn: 'root'
})
export class BkngFormPopupServ {

	constructor(private dialog: MatDialog) { }

	/**
	 * Picture preview popup
	 * @param picture
	 * @returns popup ref
	 */
	public picturePreviewPopup(picture: any): any {
		let ref: MatDialogRef<PicturePreviewPopupComponent>;
		ref = this.dialog.open(PicturePreviewPopupComponent);
		ref.componentInstance.picture = picture;
		return ref.afterClosed();
	}
	/**
	 * Merchant locations popup
	 * @param locations : All merchant locations
	 * @returns popup ref
	 */
	public merchantLocationsPopup(locations: any): any {
		let ref: MatDialogRef<MerchantLocationsPopupComponent>;
		ref = this.dialog.open(MerchantLocationsPopupComponent);
		ref.componentInstance.locations = locations;
		return ref.afterClosed();
	}
	/**
	 * Packages popup
	 * @param data: all required data
	 * @returns popup ref
	 */
	public packagesPopup(data: any) {
		let ref: MatDialogRef<PackagesPopupComponent>;
		ref = this.dialog.open(PackagesPopupComponent);
		ref.componentInstance.data = data;
		return ref.afterClosed();
	}

	public scheduleCalendarPopup(BKFrm: any, settings: any, selectedDate: any, dayDiscountBookings: any, dayDiscounts: any, dateDiscountsArray: any, dayDiscountsArray: any, prefilledData: any, isSpotAvailable: any = true, calSection: any = null, pageSett: any = null) {
		let ref: MatDialogRef<ScheduleCalendarPopupComponent>;
		ref = this.dialog.open(ScheduleCalendarPopupComponent);
		ref.componentInstance.BKFrm = BKFrm;
		ref.componentInstance.settings = settings;
		ref.componentInstance.selectedDate = selectedDate
		ref.componentInstance.dayDiscountBookings = dayDiscountBookings
		ref.componentInstance.dayDiscounts = dayDiscounts
		ref.componentInstance.dateDiscountsArray = dateDiscountsArray
		ref.componentInstance.dayDiscountsArray = dayDiscountsArray
		ref.componentInstance.prefilledData = prefilledData
		ref.componentInstance.isSpotAvailable = isSpotAvailable
		ref.componentInstance.calSection = calSection
		ref.componentInstance.pageSett = pageSett
		return ref.afterClosed();
	}
	/**
	 * View provider's reviews popup
	 * @param obj - object having the fields : `selectedProvider`, `reviews`, `customerDetails` & `totalRecords`
	 * @returns popup ref
	 */
	public viewProviderReviewPopup(obj: any): any {
		let ref: MatDialogRef<ViewProviderReviewsPopupComponent>;
		ref = this.dialog.open(ViewProviderReviewsPopupComponent);
		ref.componentInstance.selectedProv = obj.selectedProv;
		ref.componentInstance.reviews = obj.reviews;
		ref.componentInstance.customerDetail = obj.customerDetail;
		return ref.afterClosed();
	}

	public howItWorksPopup(videoUrl:any): any {
		let ref: MatDialogRef<HowItWorksPopupComponent>;
		ref = this.dialog.open(HowItWorksPopupComponent);
		ref.componentInstance.videoUrl = videoUrl;
		return ref.afterClosed();
	}
}