import { Component, OnInit, Input, ViewEncapsulation, Self } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { takeUntil } from 'rxjs';
// External library
import { ToastrService} from 'ngx-toastr';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ, InitServ, ApiServ, UtilServ, LoaderServ, LeadsServ } from '../../Services';
// Constants
import { TEXT_REG_EXP, EMAIL_REG_EXP, TWITTER_LINK } from '../../Constants';

@Component({
	selector: 'bk-contact-us',
	templateUrl: './ContactUs.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class ContactUsComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	@Input() popupId: string = '';
	phoneMask: any = this.initServ.selectedMask; // App phone number masking
	readonly twitterLink: string = TWITTER_LINK;
	contactUsForm: FormGroup;
	pageSett: any;
	varId: string = 'bk_contact_us_V1';
	variationClass: any = {
		bk_contact_us_V4 : 'tjs-contact--v4',
		bk_contact_us_V5 : 'tjs-bg-style tjs-contact--v5'
	}
	// Section fields
	section: any = {
		form: null,
		media: null,
		get_in_touch: null
	}
	loaderId: string = 'contact-us-form-loader';
	sectionData: any;
	sendSmsNotification: boolean = true;
	phoneNotifStatus: boolean = false;
	// eslint-disable-next-line max-params
	constructor(public rcServ: RenderComponentServ, private frmBldr: FormBuilder, public secServ: SectionServ, public initServ: InitServ, @Self() private destroy: NgOnDestroy, private apiServ: ApiServ, private utilServ: UtilServ, private loader: LoaderServ, private toastr: ToastrService, private leadsServ: LeadsServ) {
		this.contactUsForm = this.frmBldr.group({
			name: [null, [Validators.required, Validators.pattern(TEXT_REG_EXP)]],
			email: [null, [Validators.required, Validators.pattern(EMAIL_REG_EXP)]],
			phone_number: [null],
			send_sms_notification: [this.utilServ.getSendSmsNotifDefVal()],
			message: [null, Validators.required]
		})
	}

	ngOnInit(): void {
		if(this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.sectionData = this.rcServ.popupData[this.popupId];
		} else {
			this.sectionData = this.rcServ.pageData;
		}
		// Build section data
		if(this.secId && this.sectionData){
			this.buildSection(this.sectionData);
		}
		this.setSendSmsNotifVal();
	}

	private setSendSmsNotifVal(): void {
		this.sendSmsNotification = this.contactUsForm.controls['send_sms_notification'].value == 'yes' ? true : false;
	}
	// convenience getter for easy access to form fields
	get f() : { [key: string]: AbstractControl } {
		return this.contactUsForm.controls;
	}
	/**
	 * Build the section
	 * @param secData
	 */
	private buildSection(secData: any): void {
		this.pageSett = secData.section_settings;
		this.varId = secData.section_settings[this.secId].variation_id;
		this.secServ.setServData(this.pageSett, secData.content);
		this.section = this.secServ.buildSectionFields(this.secId, this.section, secData);
		// Phone notification status
		if(this.section && this.section.form && this.section.form.phone && this.section.form.phone?.id){
			let notificationId = secData.section_settings[this.section.form.phone?.id]?.elements?.notification;
			if(notificationId){
				this.phoneNotifStatus = this.secServ.checkEleStatus(this.pageSett, notificationId);
			}
		}
	}
	/**
	 * Handles the focus out event for the details section.
	 * This function checks the validation status of the email and phone number form controls.
	 * If the email is valid or the phone number is provided, it adds contact information to a lead.
	 */
	public detailsFocusOut(interval: number | null = null){
		if(this.f['email']?.valid || (this.f['phone_number'].value && this.f['phone_number']?.valid)){
			let sendData: any = this.contactUsForm.value;
			sendData['email'] = (sendData['email']) ? (sendData['email']).toLowerCase() : null;
			sendData['phone_number'] = sendData['phone_number'] ? this.utilServ.phoneUnmask(sendData['phone_number']) : null;
			this.leadsServ.addContactToLead(sendData, {type: 'contact', slug: 'contact-us'}, interval);
		}
	}
	/**
	 * Submit form
	 */
	public submitForm(): void {
		if(this.contactUsForm.valid){
			this.apiServ.setLoaderId(this.loaderId);
			this.loader.show(this.loaderId);
			let sendData: any = this.contactUsForm.value;
			this.detailsFocusOut(1);
			sendData['email'] = (sendData['email']).toLowerCase();
			sendData['phone_number'] = sendData['phone_number'] ? this.utilServ.phoneUnmask(sendData['phone_number']) : null;
			// Active theme
			let theme: string = this.initServ.theme ? this.initServ.theme : (this.initServ.appAdmnStngs && this.initServ.appAdmnStngs.active_theme) ? this.initServ.appAdmnStngs.active_theme : '';
			this.apiServ.callApiWithQueryParams('POST', 'FormData', {theme_slug:theme}, sendData).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, 'send'));
		} else {
			for(let i in this.contactUsForm.controls) {
				this.contactUsForm.controls[i].markAsTouched();
			}
			this.toastr.error('Please fill the required fields marked in red.');
		}
	}
	/**
	 * Set the yes/no send sms notification field value
	 * @param event: HTML event
	 */
	public sendSmsNotifChange(event: Event): void {
		const isChecked = (<HTMLInputElement>event.target).checked;
		if(isChecked){
			this.contactUsForm.controls['send_sms_notification'].setValue("yes");
		} else {
			this.contactUsForm.controls['send_sms_notification'].setValue("no");
		}
	}
	/**
	 * On result callback method
	 * @param res api
	 * @param type section/send
	 * API response handler
	 */
	private onResultCallback(res:any, type: string = 'section'): void {
		if(type == 'send'){
			if(this.apiServ.checkAPIRes(res)){
				this.contactUsForm.patchValue({
					name: null,
					email: null,
					phone_number: null,
					message: null,
					send_sms_notification: this.utilServ.getSendSmsNotifDefVal()
				});
				this.contactUsForm.markAsUntouched();
				this.toastr.success(res.message);
				this.setSendSmsNotifVal();
			} else {
				if(res && res.message){
					this.toastr.error(res.message);
				}
			}
			this.loader.hide(this.loaderId);
		} else {
			if(this.apiServ.checkAPIRes(res) && res.data){
				let secData: any = res.data;
				if(secData){
					this.rcServ.setPageData = secData;
					this.buildSection(secData);
				}
			}
		}
	}
}
