<div [ngClass]="variationId == 'bk_short_form_V3' ? 'params_version_3' : 'params_version_4 col-md-12 col-lg-12 col-xl-10 mx-auto'" [formGroup]="pricingParamForm" [id]="section?.id" *ngIf="settings && section">
	<!-- Title -->
	<div class="mb-30" *ngIf="section?.title">
		<h4 class="mb-0 bk-title" [ngClass]="variationId == 'bk_short_form_V3' ? 'fw-bold' : 'display-5 text-center fw-normal'" [id]="sectionIds?.title"  [innerHTML]="section?.title | safeHtml"></h4>
	</div>
	<div [ngClass]="{'combine-component row justify-content-center' : variationId == 'bk_short_form_V4'}">
		<div [ngClass]="{'col-md-6' : variationId == 'bk_short_form_V4', 'position-absolute':!(services && services.length > 1)}">
			<!-- Service category -->
			<bk-short-form-service-cat
				[sectionIds]="sectionIds"
				[bookingType]="bookingType"
				[variationId]="variationId"
				[section]="parentSection?.service"
				[serviceForm]="pricingParamForm"
				[selectedLocation]="selectedLocation"
				[selectedServiceType]="selectedServiceType"
				[selectedFrequency]="selectedFrequency"
				[settings]="settings"
				(serviceChange)="serviceChange.emit($event)"
				(hourlyServiceChange)="hourlyServiceChange.emit()"
				(getServices)="services=$event"
				[isShortForm]="true"
			>
			</bk-short-form-service-cat>
		</div>
		<div [ngClass]="{'col-md-6' : variationId == 'bk_short_form_V4'}">
			<!-- Frequency -->
			<bk-short-form-frequency
				[sectionIds]="sectionIds"
				[variationId]="variationId"
				[bookingType]="bookingType"
				[section]="parentSection?.frequency"
				[frequencyForm]="pricingParamForm"
				[selectedLocation]="selectedLocation"
				[selectedServiceType]="selectedServiceType"
				[selectedFrequency]="selectedFrequency"
				[settings]="settings"
				(frequencyChange)="frequencyChange.emit($event)" >
			</bk-short-form-frequency>
		</div>
	</div>
	<bk-short-frm-hourly
		[sectionIds]="sectionIds"
		[bookingType]="bookingType"
		[variationId]="variationId"
		[section]="parentSection?.service"
		[serviceForm]="pricingParamForm"
		[selectedLocation]="selectedLocation"
		[selectedServiceType]="selectedServiceType"
		[selectedFrequency]="selectedFrequency"
		[settings]="settings"
		(serviceChange)="serviceChange.emit($event)"
		(hourlyServiceChange)="hourlyServiceChange.emit()">

	</bk-short-frm-hourly>
	<!-- Pricing parameters -->
	<div class="row" [formGroup]="pricingParamForm" [id]="section?.id" *ngIf="settings && section && pricingParams && pricingParams.length > 0">
		<div [ngClass]="variationId == 'bk_short_form_V3' ? 'col-md-12 mb-20' : 'col-md-6'" *ngFor="let pricingParam of pricingParams;let i=index;trackBy: trackByParamValue">
			<div [ngClass]="{'d-md-flex w-100 align-items-center bg-white rounded shadow-sm rounded p-20 px-md-15 py-md-10 mb-20' : variationId == 'bk_short_form_V4'}">
				<!-- Label -->
				<label class="form-label me-sm-15 me-10 bk-form-label" [ngClass]="{' col-md-4 mb-10 mb-md-0' : variationId == 'bk_short_form_V4'}" [id]="sectionIds?.label">{{pricingParam?.cat_name | translate}}</label>
				<!-- Select list -->
				<select class="form-select bk-select" [ngClass]="{'pe-35 fs-15 form-select-sm tjs-border-md-0 shadow-none':variationId == 'bk_short_form_V4'}" *ngIf="pricingParam?.value && (pricingParam?.value).length > 0" (change)="pricingParameterChange($event, pricingParam)" [attr.disabled]="isDisabled">
					<ng-container *ngFor="let param of pricingParam?.value;trackBy: utilServ.trackByFnIndex">
						<option [value]="param?.id" [selected]="(param?.variable_category == pricingParam?.id && (+param?.id == +pricingParam?.selected_value)) ? true : null">
							<span class="bk-select-option">{{(param.id == 'none') ? param?.param_name : utilServ.getFormParamName(param) | translate}}
							</span>
							<span *ngIf="['reschedule', 'draft'].includes(bookingType) && param.status == 8">{{initServ?.appStr?.text?.archived | translate}}</span>
						</option>
					</ng-container>
				</select>
			</div>
		</div>
	</div>
</div>