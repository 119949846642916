/**
 * Authenticates the user event like login/signup/reset password/prvdr signup/loginAsAdmin/LoginAsUser etc
 */
import { Injectable } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { Router } from '@angular/router';
// External lib
import { ToastrService } from 'ngx-toastr';
import { sha512Hash } from 'iron-crypto-pkg';
// Constants
import { DEV_HOST, IS_DEV } from 'src/app/Constants';
// Services
import { ApiServ, InitServ, LoaderServ, UtilServ } from 'src/app/Services';
import { APIRes } from 'src/app/Interfaces';
interface RedirectOptions {
	role: string;
	access_token: string;
	onetime_access_token: string;
	is_default_setup: boolean;
	isRedirect: boolean;
}
interface AuthOptions {
	redirectUrl?: string;
	loaderId?: string;
	isRedirect?: boolean;
	isSession?: boolean;
	isBooknow?: boolean;
	isSetOldData?: boolean;
	authKey?: string;
	isSocial?: boolean;
}
interface AuthCustomHeader {'Content-Type': string; Ip: string; Authtoken?: string}

@Injectable()
export class AuthServ {
	// Private variables
	private destroy = new Subject<void>();
	public token: string;
	ipAddress: any = this.initServ.ipAddress ? this.initServ.ipAddress : '107.180.54.183';
	cypherToken: string = 'NPZ8fvABP5pKwU3';
	cypherTokenTwo: string = 'AP6EHEG37zJ2c9j';

	// eslint-disable-next-line max-params
	constructor(private utilServ: UtilServ, private apiServ: ApiServ, private toastr: ToastrService, private initServ: InitServ, private router: Router, private loader: LoaderServ) {
		const currentUser = this.utilServ.appLocalStorage();
		this.token = currentUser?.token;
	}

	/**
	 * Authenticates the user and manages login actions.
	 * Sets loader based on provided loaderId and constructs headers for the API call.
	 * Upon successful API response, handles user session, localStorage, and redirection based on role and options.
	 * Handles error and hides loader if the login fails.
	 * @param user User credentials and related login data.
	 * @param options Optional settings for redirection, loader ID, and other login behaviors.
	 * @returns Observable of login status.
	 */
	public login(user: any, options: AuthOptions = {}): Observable<any> {
		let { loaderId = 'main' } = options;
		// Set loader and header
		this.setLoader(loaderId);

		// API call to login
		return this.apiServ.callApi('POST', 'Login', user, this.authCustomHeader()).pipe(takeUntil(this.destroy)).toPromise().then(async (resp: any) => {
			if (!this.apiServ.checkAPIRes(resp)) {
				this.handleAuthError(resp, loaderId);
				return false;
			}

			await this.handleLoginAndPrvdrSuccessResp(options, resp);
			this.loader.hide(loaderId);
			return true;
		});
	}

	/**
	 * Displays loader based on the provided loader ID.
	 * Calls the API service to set the loader ID, then displays the loader.
	 * @param loaderId Identifier for the loader.
	 */
	private setLoader(loaderId: string): void {
		if (loaderId) {
			this.apiServ.setLoaderId(loaderId);
			this.loader.show(loaderId);
		}
	}

	private authCustomHeader():AuthCustomHeader {
		return { 'Content-Type': 'application/json', 'Ip': this.ipAddress };
	}

	/**
	 * Handles login errors and hides the loader.
	 * If the response contains an error message, displays it as a toastr error notification.
	 * @param resp API response containing error information.
	 * @param loaderId Identifier for the loader to hide after error handling.
	 */
	private handleAuthError(resp: any, loaderId: string = 'main'): void {
		if (resp?.message) {
			this.toastr.error(resp.message, '', { timeOut: 10000 });
		}
		this.loader.hide(loaderId);
	}

	/**
	 * Handles successful login response and manages redirection based on the user's role and other conditions.
	 * @param options - Options to control login behavior such as redirection and session management.
	 * @param resp - The response object from the login API containing user details.
	 */
	private async handleLoginAndPrvdrSuccessResp(options: AuthOptions = {}, resp: any): Promise<void> {
		let { isRedirect = false } = options;
		if(!resp?.data){
			resp.data = {};
		}
		let { role='', access_token='', onetime_access_token='', is_default_setup=false } = resp.data;

		await this.setUserInfo(options, resp.data);
		// Get redirect data based on login response
		let redirectData = this.getRedirectUrl({ role, access_token, onetime_access_token, is_default_setup, isRedirect });

		// Handle redirection or navigation
		if (isRedirect) {
			this.handleAuthRedirect(redirectData);
		} else if (role !== 'customer') {
			window.location.href = `${window.location.protocol}//${IS_DEV ? DEV_HOST : window.location.hostname}/${redirectData.redirect}`;
		}
	}

	/**
	 * Sets logged-in user information based on the provided login options and response data.
	 * Handles storing user data in local storage, initializing user-specific settings,
	 * and ensuring compatibility with session and iframe contexts.
	 * @param {AuthOptions} options - Options to customize the login behavior.
	 * @param {any} respData - Response data containing user information.
	 */
	private async setUserInfo(options: AuthOptions = {}, respData: any): Promise<void> {
		let { isSession = false, isBooknow = false, isSetOldData = false } = options;
		// Set user data in local storage if not in session or iframe
		if (!this.utilServ.isObjectEmpty(respData) && (!isSession || !this.utilServ.inIframe(this.utilServ.embedStatus))) {
			let { role, id } = respData;
			await this.setUserLocalStorage(respData, isSetOldData);

			// Initialize logged-in user if role is customer and either 'Booknow' or not in iframe
			if (role === 'customer' && (isBooknow || !this.utilServ.inIframe(this.utilServ.embedStatus))) {
				await this.initServ.loggedInUser(id);
			}
		}
	}

	/**
	 * Stores user information in local storage.
	 * @param userInfo - Information of the user to be stored.
	 * @param isSetOldData - Flag to determine if old data should be preserved in storage.
	 */
	private setUserLocalStorage(userInfo: any, isSetOldData: boolean = false): void {
		let { session_token, id, first_name, last_name, photo_url, role, status, access_token, is_default_setup, onetime_access_token, is_new } = userInfo;
		let localStorageData = {
			Ip: this.ipAddress,
			token: session_token,
			id,
			first_name,
			last_name,
			photo_url,
			role,
			status,
			access_token,
			is_default_setup,
			onetime_access_token,
			is_new,
			old_local_storage: isSetOldData && this.utilServ.inIframe(this.utilServ.embedStatus) ? this.utilServ.appLocalStorage('currentUser', true) : null
		}
		try {
			localStorage.setItem('currentUser', JSON.stringify(localStorageData));
		} catch (_err) { /* empty */ }
	}

	/**
	 * Determines the URL and path to redirect the user after successful login based on their role and setup.
	 * @param options - Options including role, access tokens, and setup status.
	 * @returns The redirect URL and path for the given user role.
	 */
	private getRedirectUrl(options: RedirectOptions): { redirectUrl: string, redirect: string } {
		let { role, access_token, onetime_access_token, is_default_setup } = options;
		if(!role){
			return {
				redirectUrl: '/login',
				redirect: `/login`
			}
		}
		switch (role) {
			case 'provider':
				return {
					redirectUrl: '/provider',
					redirect: `/provider/login/${access_token}`
				}
			case 'merchant':
				return is_default_setup ? {
					redirectUrl: '/admin/dashboard',
					redirect: `/admin/login/${onetime_access_token}`
				} : {
					redirectUrl: `/admin/start/${access_token}`,
					redirect: `/admin/start/${access_token}`
				};
			case 'staff':
				return {
					redirectUrl: '/admin/dashboard',
					redirect: `/admin/login/${onetime_access_token}`
				};
			default:
				return {
					redirectUrl: '/dashboard',
					redirect: `/login/${access_token}`
				}
		}
	}

	/**
	 * Redirects the user based on the context (iframe or not) and login type (social or standard).
	 * @param {Object} redirectData - Contains redirect URL and token-based redirect path.
	 * @param {boolean} isSocial - Flag to check if the login is via social media.
	 * @param {string} respMsg - Response message to display after redirection.
	 */
	private handleAuthRedirect(redirectData: { redirectUrl: string, redirect: string }, isSocial: boolean= false, respMsg: string = ''): void {
		// Check if the current context is within an iframe and if `initServ.theme` is not set.
		if (this.utilServ.inIframe(this.utilServ.embedStatus) && !this.initServ.theme) {
				// Ensure `top` is not null or undefined before using it.
			if (top) {
				top.window.location.href = redirectData.redirect;
			}
		} else{
			this.authRedirection(redirectData, isSocial, respMsg);
		}
	}

	/**
	 * Handles the actual redirection logic and displays success messages after navigation.
	 * @param {Object} redirectData - Contains redirect URL and token-based redirect path.
	 * @param {boolean} isSocial - Flag to check if the login is via social media.
	 * @param {string} respMsg - Response message to display after redirection.
	 */
	private authRedirection(redirectData: { redirectUrl: string, redirect: string }, isSocial: boolean = false, respMsg: string = ''): void {
		if (redirectData.redirectUrl === '/dashboard') {
			// Navigate to the dashboard and show appropriate success message
			this.router.navigate([`/${this.initServ.appDynamicRoutes['dashboard']}`]).then(() => {
				if(isSocial){
					this.toastr.success(respMsg);
				} else {
					this.toastr.success(this.initServ.appStr.toastr.signUp);
				}
			});
		} else {
			// Show a success message after redirection logic.
			this.toastr.success(this.initServ.appStr.toastr.loggedIn);
			window.location.href = redirectData.redirectUrl;
		}
	}

	/**
	 * Signs up a user as a provider through an API call.
	 * Displays loader based on loaderId, makes signup API call, and handles response accordingly.
	 * @param user Provider signup data.
	 * @param options Optional settings for loader visibility, redirection, and session handling.
	 */
	public signupAsPrvdrApi(user: any, options: AuthOptions = {}): void {
		let { redirectUrl = '', isRedirect = false, loaderId = '', isSession = false, isSetOldData = false } = options;
		// Set loader id for hide the loader in case of api handle error.
		loaderId = loaderId ? loaderId : 'main';
		if (loaderId) {
			this.apiServ.setLoaderId(loaderId);
			this.loader.show(loaderId);
		}
		this.apiServ.callApi('POST', 'ProviderSignup', user, this.authCustomHeader()).pipe(takeUntil(this.destroy)).subscribe((resp: any) => this.signupAsPrvdrApiResp(resp, { redirectUrl, isRedirect, loaderId, isSession, isSetOldData }));
	}

	/**
	 * Handles the response from the signup API for providers.
	 * @param resp - The response from the signup API.
	 * @param options - Configuration options for login including redirect URL, session control, and local storage settings.
	 * @returns {boolean | void} - Returns false if API response indicates a failure; otherwise, returns nothing.
	 */
	private async signupAsPrvdrApiResp(resp: any, options: AuthOptions = {}): Promise<boolean | void> {
		let { loaderId = '' } = options;
		if (!this.apiServ.checkAPIRes(resp)) {
			this.handleAuthError(resp, loaderId);
			return false;
		}

		await this.handleLoginAndPrvdrSuccessResp(options, resp);
		this.loader.hide(loaderId);
		return true;
	}

	/**
	 * Logs in a user with the provided access token, handles encryption for secure API communication,
	 * and manages user session initialization.
	 * @param {any} accessToken - The access token for the user login.
	 * @param {string} loaderId - The loader ID for managing UI loading states (default: 'main').
	 * @param {boolean} isSetOldData - Whether to set old user data (default: false).
	 * @returns {Promise<boolean>} - Resolves to true if login is successful, otherwise false.
	 */
	public async loginAsUser(accessToken: any, loaderId: string = 'main', isSetOldData: boolean = false): Promise<boolean> {
		try {
			this.apiServ.setLoaderId(loaderId);
			this.loader.show(loaderId);

			// Construct the encrypted key for secure API communication
			let token = `${this.cypherToken}${accessToken}${this.cypherTokenTwo}${this.ipAddress.replace(/\./g, '')}`;
			let encryptKey = await sha512Hash(token);
			let payload = { access_token: accessToken };

			// API call to login
			return await this.apiServ.callApiWithPathVariables('POST', 'LoginAsUser', [encryptKey], payload, this.authCustomHeader()).pipe(takeUntil(this.destroy)).toPromise().then(async (resp: any) => {
				// Check API response validity
				if (!this.apiServ.checkAPIRes(resp)) {
					this.handleAuthError(resp);
					return false;
				}

				// Set user data and initialize user session
				let userInfo: any = resp.data;
				await this.setUserLocalStorage(userInfo, isSetOldData);

				if (userInfo.role === 'customer') {
					await this.initServ.loggedInUser(userInfo.id);
				}

				return true;
			});
		} finally {
			// Hide the loader once login is complete
			this.loader.hide(loaderId);
		}
	}

	/**
	 * Logs in an admin user using a one-time access token.
	 * Handles encryption of sensitive data, validates the API response, and sets up the admin session.
	 * On successful login, redirects the admin to the dashboard.
	 * @param {any} currentUser - The current admin user's information, including the one-time access token and IP address.
	 * @returns {Promise<Observable<any>>} - Resolves with the API response if login is successful, otherwise false.
	 */
	public async loginAsAdmin(currentUser: any): Promise<Observable<any>> {
		let token = this.cypherToken + currentUser.admin_token + this.cypherTokenTwo + (currentUser.Ip).replace(/\./g, '')
		let encryptKey = await sha512Hash(token);
		let payload = { onetime_access_token: currentUser.admin_token };
		return this.apiServ.callApiWithPathVariables('POST', 'LoginWithOtToken', [this.utilServ.userId(), encryptKey], payload).pipe(takeUntil(this.destroy)).toPromise().then(async (resp: any) => {
			// Check API response validity
			if (!this.apiServ.checkAPIRes(resp)) {
				this.handleAuthError(resp);
				return false;
			}

			// Extract user data from the response and set up local storage
			let userInfo: any = resp.data;
			this.setUserLocalStorage(userInfo);

			// Notify the admin of successful login and redirect to the dashboard
			this.toastr.success(this.initServ.appStr.toastr.loggedIn);
			window.location.href = `${window.location.protocol}//${IS_DEV ? DEV_HOST : window.location.hostname}/admin/dashboard`;

			return true;
		});
	}

	/**
	 * Handles user signup by sending the provided payload to the server.
	 * Includes authentication token generation, API call for signup, and subsequent redirection.
	 * Supports options for handling session, social logins, and UI loaders.
	 * @param {any} payload - The data to be sent for signup.
	 * @param {AuthOptions} options - Additional options for customization (auth key, loader ID, session flag, social login flag).
	 * @returns {Promise<boolean>} - Resolves to true if signup is successful, otherwise false.
	 */
	public async signup(payload: any, options: AuthOptions = {authKey:'', loaderId: 'main', isSession: false, isSocial:false}): Promise<boolean>{
		let { authKey, loaderId, isSocial } = options;

		// Show loader for the signup process
		this.apiServ.setLoaderId(loaderId);
		this.loader.show(loaderId);

		// Generate an authentication token using the provided auth key
		let authToken = await sha512Hash(authKey as string);
		let header: AuthCustomHeader = this.authCustomHeader();
		header['Authtoken'] = authToken;

		// Make an API call to register the user
		return this.apiServ.callApi('POST', 'Signup', payload, header).pipe(takeUntil(this.destroy)).toPromise().then(async (resp: any) => {

			// Check the API response for errors
			if (!this.apiServ.checkAPIRes(resp)) {
				this.handleAuthError(resp, loaderId as string);
				return false;
			}

			// Extract user information from the response and set user data
			let userInfo = resp.data;
			await this.setUserInfo(options, userInfo);
			// Prepare redirection data for post-signup navigation
			let redirectData = {
				redirectUrl: '/dashboard',
				redirect: `/login/${userInfo.access_token}`,
			}

			// Handle redirection and UI updates
			this.handleAuthRedirect(redirectData, isSocial, resp.message);
			this.loader.hide(loaderId);
			return true;
		});
	}


	/**
	 * Initiates a signup API call for an unverified provider.
	 * This function encrypts the authentication key, prepares request headers,
	 * and sends the data to the API. The response is handled by `prvdrSignupApiResp`.
	 * @param {any} data - The provider's data to be sent to the signup API.
	 * @param {any} authKey - The authentication key used for securing the API request.
	 * @returns {Promise<void>} - Resolves when the API call is made and the response is handled.
	 */
	public async prvdrSignupApi(data: any, authKey: string): Promise<void> {
		// Generate an authentication token using the provided auth key
		let authToken = await sha512Hash(authKey as string);
		let header: AuthCustomHeader = this.authCustomHeader();
		header['Authtoken'] = authToken;

		// Make the API call to register an unverified provider
		this.apiServ.callApi('POST', 'UnVerifiedPrvdr', data, header).pipe(takeUntil(this.destroy)).subscribe((resp: APIRes) => {
			if (resp && resp?.api_status == 2) {
				this.toastr.success(resp?.message);
				this.router.navigate(['/signup']);
			} else {
				this.toastr.error(resp?.message);
			}
			this.loader.hide();
		});
	}

	/**
	 * Sends a password reset or request reset API call.
	 * Manages the loader visibility during the API call and processes the response.
	 * @param {boolean} isReq - Password reset request
	 * @param {any} payload - The payload containing data for the API request.
	 * @param {string} loaderId - The ID of the loader to display during the operation.
	 * @param {boolean} isRedirect - Determines whether to redirect after a successful response.
	 * @returns {Promise<any>} - Resolves with the API response handling result.
	 */
	public passwordResetApi(isReq: boolean, payload: any, loaderId: string, isRedirect: boolean = true): any {
		let apiName: string = isReq ? 'ReqResetPassword' : 'ResetPassword';
		// Set the loader ID and display the loader
		this.apiServ.setLoaderId(loaderId);
		this.loader.show(loaderId);

		// Call the API and handle the response asynchronously
		return this.apiServ.callApi('POST', apiName, payload).pipe(takeUntil(this.destroy)).pipe(takeUntil(this.destroy)).toPromise().then(async (resp: any) => this.handlePasswordResp(resp, isRedirect, loaderId));
	}

	/**
	 * Handles the response from a password reset or request reset API call.
	 * Displays success/error messages and optionally redirects to the login page.
	 * @param {any} resp - The response object from the API.
	 * @param {boolean} isRedirect - Indicates whether to redirect upon success.
	 * @param {string} loaderId - The ID of the loader to hide after processing.
	 * @returns {boolean} - Indicates whether the API response was successful.
	 */
	private handlePasswordResp(resp: any, isRedirect: boolean, loaderId: string): boolean {
		// Check if the API response is valid
		if (!this.apiServ.checkAPIRes(resp)) {
			this.handleAuthError(resp, loaderId as string);
			return false;
		}

		if (isRedirect) {
			// Redirect to the login page with optional embed query parameters
			let loginUrl: string = `/${this.initServ.appDynamicRoutes['login']}`;
			let redirectOpts = this.utilServ.embedStatus ? { queryParams: { embed: 'true' } } : undefined;
			this.router.navigate([loginUrl], redirectOpts).then(() => {
				this.toastr.success(resp.message);
			});
		} else {
			// Show a success message without redirection
			this.toastr.success(resp.message);
		}

		this.loader.hide(loaderId);
		return true;

	}

	/**
	 * Logs out the current user by calling the logout API, clearing user data,
	 * and redirecting to the appropriate logout URL.
	 */
	public logout(): void {
		this.apiServ.callApiWithPathVariables('GET', 'Logout', [this.utilServ.userId()]).pipe(takeUntil(this.destroy)).subscribe((resp: any) => {
			if (this.apiServ.checkAPIRes(resp)) {
				this.removeCurrentUser();
				window.location.href = this.getLogoutUrl();
			} else {
				let errorMessage = resp?.message || 'Logout failed.';
				this.toastr.error(errorMessage, '', { timeOut: 10000 });
			}
		});
	}

	/**
	 * Removes the current user data from local storage and resets user state in the application.
	 */
	public removeCurrentUser(): void {
		try {
			localStorage.removeItem('currentUser');
			localStorage.removeItem('passwordProtected');
			this.initServ._userInfo = null;
			this.initServ.isUserProfile.next(true);
		} catch { /* empty */ }
	}

	/**
	 * Retrieves the logout URL based on theme settings or defaults to the base host URL.
	 * @returns A string representing the URL to navigate to after logout.
	 */
	private getLogoutUrl(): string {
		let siteSettings = this.initServ.siteData?.theme_settings?.settings;
		let logoutLink = siteSettings?.menu_logout_link;
		return logoutLink ? this.utilServ.checkHttpExist(logoutLink) : `${window.location.protocol}//${IS_DEV ? DEV_HOST : window.location.hostname}/`;
	}

	/**
	 * Redirects the user to the appropriate page based on embed status and user information.
	 * @returns A string representing the redirection URL or an empty string if no redirection is required.
	 */
	public redirectFromEmbed(): string {
		if(this.utilServ.inIframe(this.utilServ.embedStatus) && !this.initServ.theme){
			let currentUser: any = this.utilServ.appLocalStorage('currentUser', true);
			return (currentUser?.access_token && (!currentUser.old_local_storage || (currentUser?.old_local_storage.role == 'customer'))) ? `/login/${currentUser.access_token}` : '';
		} else {
			return '';
		}
	}
}
