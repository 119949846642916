import { Injectable } from "@angular/core";
import { AbstractControl, ValidatorFn, AsyncValidatorFn, ValidationErrors, Validators, FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject, of } from 'rxjs';
// External library
import { ToastrService } from 'ngx-toastr';
// Constants
import { EMAIL_REG_EXP, EXTRA_LONG_TEXT_LIMIT, LONG_TEXT_LIMIT, PASSWORD_REG_EXP, PHONE_REG_EXP, SHORT_TEXT_LIMIT } from "src/app/Constants";
// Services
import { ApiServ, InitServ } from "src/app/Services";

function isEmptyInputValue(value: any): boolean {
	// we don't check for string here so it also works with arrays
	return value === null || value.length === 0;
}

@Injectable({
	providedIn: "root"
})
export class CustomValidators {
	// Private variables
	private destroy = new Subject<void>();
	constructor(private apiServ: ApiServ, private toastr: ToastrService, private initServ: InitServ) { }

	/**
	 * Match the two password control value
	 * @param controlName
	 * @param checkControlName :
	 * @returns Error | null
	 */
	public static matchPassword(controlName: string, checkControlName: string): ValidatorFn {
		return (controls: AbstractControl) => {
			const control: any = controls.get(controlName);
			const checkControl: any = controls.get(checkControlName);
			// Already error
			if (checkControl.errors && !checkControl.errors.matching) {
				return null;
			}
			// Set error on matchingControl if validation fails
			if (control.value !== checkControl.value) {
				checkControl.setErrors({ matching: true });
				return { matching: true };
			} else {
				return null;
			}
		};
	}
	/**
	 * AsyncValidatorFn to check the email already exists
	 * @param initialEmail
	 * @param id
	 * @returns Error | null
	 */
	public existingEmail(initialEmail?: string, id?: number): AsyncValidatorFn {
		return (control: AbstractControl): Observable<ValidationErrors | null> => {
			// Empty input value
			if (isEmptyInputValue(control.value)) {
				return of(null);
			} else if (control.value === initialEmail) {
				return of(null);
			} else {
				return this.emailExist(control.value, id);
			}
		};
	}
	/**
	 * Check the email already exists
	 * @param email User email
	 * @param id Logged user id
	 * @returns null and object { emailExists: true }
	 */
	public emailExist(email: string, id?: number): any {
		let queryParams: any = { email: email.toLowerCase() };
		// Empty input value
		if (isEmptyInputValue(email)) {
			return null;
		} else {
			// Logged user id
			if (id) { queryParams['uid'] = id; }
			return this.apiServ.callApiWithQueryParams('GET', 'VerifyEmail', queryParams).pipe(takeUntil(this.destroy)).toPromise().then((res: any) => {
				if (this.apiServ.checkAPIRes(res)) {
					// Return null if there is no error
					return null;
				}else if(res && res.api_status == 2){
					// if api_status is 2, then return true
					// this case specifically arises when provider has already submitted a request and yet not verified from admin.
					// during that if a provider tries to signup with same email
					return { prvdrEmailExists: true }
				}
				// if res is error, user email exists, return true
				return { emailExists: true };
			});
		}
	}
	/**
	 * AsyncValidatorFn to check the email already exists
	 * @param initialPhone
	 * @param id
	 * @returns Error | null
	 */
	public existingPhone(initialPhone?: string, id?: number): AsyncValidatorFn {
		return (control: AbstractControl): Observable<ValidationErrors | null> => {
			// Empty input value
			if (isEmptyInputValue(control.value)) {
				return of(null);
			} else if (control.value === initialPhone) {
				return of(null);
			} else {
				return this.phoneExist(control.value, id);
			}
		};
	}
	/**
	 * Check the phone already exists
	 * @param phone User phone
	 * @param id Logged user id
	 * @returns null and object { phoneExists: true }
	 */
	public phoneExist(phone: string, id?: number): any {
		let queryParams: any = { phone: phone }
		// Empty input value
		if (isEmptyInputValue(phone)) {
			return null;
		} else {
			// Logged user id
			if (id) { queryParams['uid'] = id; }
			return this.apiServ.callApiWithQueryParams('GET', 'VerifyPhone', queryParams).pipe(takeUntil(this.destroy)).toPromise().then((res: any) => {
				if (this.apiServ.checkAPIRes(res)) {
					// Return null if there is no error
					return null;
				}
				// if res is error, user phone exists, return true
				return { phoneExists: true };
			});
		}
	}
	/**
	 * Check the giftcard amount validation
	 * @param editId: Gift card id
	 * @returns Error | null
	 */
	public static giftCardAmount(editId: string | number, admnStngs: any): ValidatorFn {
		let minAmount: number = 0, bypassAmountValidation: boolean = false;
		// Get the min amount and amount validation from admin settings
		if (admnStngs && admnStngs.merchant_settings && admnStngs.merchant_settings?.admin) {
			minAmount = admnStngs.merchant_settings?.admin?.min_gift_card_amount;
			bypassAmountValidation = (admnStngs.merchant_settings?.admin?.bypass_min_amount_vali_on_edit && admnStngs.merchant_settings?.admin?.bypass_min_amount_vali_on_edit == 'yes') ? true : false;
		}
		const validateVal = (editId && bypassAmountValidation) ? false : true;
		return (control: AbstractControl) => {
			// Empty value
			if (isEmptyInputValue(control.value)) {
				return null;
			}
			// Already error
			if (control.errors && !control.errors.amountCheck) {
				return null;
			}
			// Set error on check min value if validation fails
			if (control.value < minAmount && validateVal) {
				control.setErrors({ amountCheck: true });
				return { amountCheck: true };
			} else {
				return null;
			}
		};
	}

	/**
	 * Check the giftcard amount validation
	 * @param editId: Gift card id
	 * @returns Error | null
	 */
	public static giftCardMaxAmount(editId: string | number, admnStngs: any): ValidatorFn {
		let minAmount: number = 0, maxAmount: number = 0;
		// Get the min amount and amount validation from admin settings
		if(admnStngs && admnStngs.merchant_settings && admnStngs.merchant_settings?.admin){
			minAmount = admnStngs.merchant_settings?.admin?.min_gift_card_amount;
			if(admnStngs.merchant_settings.admin?.enable_max_gift_card_limit == 'yes'){
				maxAmount = admnStngs.merchant_settings?.admin?.max_gift_card_amount;
			}
		}
		const validateVal = editId ? false : true;
		return (control: AbstractControl) => {
			// Empty value
			if (isEmptyInputValue(control.value)) return null;
			// Already error
			if (control.errors && !control.errors.maxAmountCheck) return null;

			// Set error on check min value if validation fails
			if (maxAmount > minAmount && control.value > maxAmount && validateVal) {
				control.setErrors({ maxAmountCheck: true });
				return { maxAmountCheck: true };
			} else {
				return null;
			}
		};
	}
	/**
	 * Set/clear the required validator
	 * @param value true/false for set/clear validator
	 * @param control form control
	 */
	public static requiredValidator(value: boolean = false, control: AbstractControl): void {
		control.markAsUntouched();
		if (value) {
			control.setValidators([Validators.required]);
		} else {
			control.clearValidators();
		}
		control.updateValueAndValidity();
	}
	/**
	 * Set the two decimal number on input field
	 * @param control from control
	 */
	public setControlDecimalNo(control: AbstractControl): void {
		let newValue = parseFloat(control.value).toFixed(2);
		if (newValue != 'NaN') {
			control.setValue(newValue);
		} else {
			control.setValue('0.00');
		}
	}
	/**
	 * Email validator with regular expression
	 * @returns
	 */
	public emailValid(control: AbstractControl): boolean {
		if (control.value) {
			control.setValue((control.value).replace(/ /g, ''));
			let emails = (control.value).split(',');
			if (emails && emails.length > 0) {
				for (let email of emails) {
					if (!(EMAIL_REG_EXP).test(email)) {
						this.toastr.error(this.initServ.appStr.toastr.refEmailError + email);
						return false;
					}
				}
			}
		}
		return true;
	}
	/**
	 * Phone number validator with regular expression
	 */
	public phoneValidate(value: any): any {
		let phone = value ? (value).split(',') : '';
		let numbers: string[] = [];
		let phoneError = 0;
		if (phone) {
			phone.forEach((ele: string) => {
				let masked = ele.replace(/(\d{3})\-?(\d{3})\-?(\d{4})/, '$1-$2-$3');
				masked = masked.trim();
				if (masked.length == 12) {
					let phoneRegExp = PHONE_REG_EXP.test(masked);
					if (phoneRegExp == true) {
						numbers.push(masked);
					}
				} else {
					phoneError = 1;
				}
			});
		}
		return { phoneError, numbers };
	}
	public shortTextValidate(control: FormControl): "" | { shortTextValidationCheck: boolean; } {
		return (control.value && (control.value).length > SHORT_TEXT_LIMIT) ? { 'shortTextValidationCheck': true } : '';
	}
	public longTextValidate(control: FormControl): "" | { longTextValidationCheck: boolean; } {
		return (control.value && (control.value).length > LONG_TEXT_LIMIT) ? { 'longTextValidationCheck': true } : '';
	}
	public extraLongTextValidate(control: FormControl): "" | { extraLongTextValidationCheck: boolean; } {
		return (control.value && (control.value).length > EXTRA_LONG_TEXT_LIMIT) ? { 'extraLongTextValidationCheck': true } : '';
	}
	public minValueValidate(min: number): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (this.isEmptyInputValue(control.value) || this.isEmptyInputValue(min)) {
				return null;  // don't validate empty values to allow optional controls
			}
			const value = parseFloat(control.value);
			return !isNaN(value) && +value < +min ? { 'minValueValidate': { 'minValueValidate': min, 'actual': control.value } } : null;
		};
	}
	/**
	 * Check if the object is a string or array before evaluating the length attribute.
	 * This avoids falsely rejecting objects that contain a custom length attribute.
	 * For example, the object {id: 1, length: 0, width: 0} should not be returned as empty.
	 */
	private isEmptyInputValue(value: any): boolean {
		return value == null || ((typeof value === 'string' || Array.isArray(value)) && value.length === 0);
	}

	/**
	 * Validator function to enforce strong password requirements:
	 * - Minimum of 12 characters
	 * - Maximum of 72 characters
	 * - Includes at least one digit.
	 * @returns Returns `null` if the password is strong, otherwise returns an error object `{ strongPassword: true }`.
	 */
	public static strongPasswordValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			let password = control.value;
			if (!password) {
				// If there's no value, skip the strong password validation
				return null;
			}

			//Check if the password matches the pattern
			let valid = PASSWORD_REG_EXP.test(password);

			// If the password is not valid, return an error object
			return valid ? null : { strongPassword: true };
		}
	}
}
