import { Component, Input, OnInit, Self, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { takeUntil } from "rxjs/operators";
// Services
import { NgOnDestroy, InitServ, ApiServ, UtilServ, SectionServ } from '../../../../Services';
import { BkngFormPopupServ } from '../../BkngFormPopup.service';

@Component({
	selector: 'bk-view-provider-reviews',
	template: `
		<ng-container *ngIf="reviewsStatus && selectedProv && selectedProv.id && reviews && reviews.length > 0">
			<a class="d-inline-block fs-14 tjs-pointer" translate (click)="bkngFormPopupServ.viewProviderReviewPopup({selectedProv, reviews, customerDetail})">View Reviews</a>
		</ng-container>
	`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class ViewProviderReviewsComponent implements OnInit {
	// Variables
	@Input() selectedProv: any;
	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	reviewsStatus: boolean = false;
	customerDetail: string = '';
	reviews: any;
	limit: number = 5;

	constructor(private initServ: InitServ, private apiServ: ApiServ, @Self() private destroy: NgOnDestroy, public utilServ: UtilServ, public bkngFormPopupServ: BkngFormPopupServ, private cDRef: ChangeDetectorRef, public secServ: SectionServ) { }

	ngOnInit() {
		if(this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings?.customers && this.admnStngs.merchant_settings?.customers?.details_to_show && (this.admnStngs.merchant_settings.customers.details_to_show).length > 0){
			this.reviewsStatus = ((this.admnStngs.merchant_settings.customers.details_to_show).includes('read_reviews')) ? true : false;
			this.customerDetail = this.admnStngs.merchant_settings?.customers?.read_review_customer_detail;
		}
		if(this.reviewsStatus && this.selectedProv && this.selectedProv.id){
			this.getReviews();
		}
	}
	/**
	 * Get the reviews
	 */
	private getReviews(): void {
		let queryParams = {limit: this.limit, page: 1};
		this.apiServ.callApiWithPathQueryVars('GET', 'ProvReviews', [this.selectedProv.id], queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res));
	}
	/**
	 * On result callback method
	 * @param res: api
	 * API response handler
	 */
	private onResultCallback(res:any): void {
		if(this.apiServ.checkAPIRes(res) && res.data){
			this.reviews = res.data;
			this.cDRef.detectChanges();
		}
	}
	/**
	 * Get more reviews on click of load more link
	 */
	// public loadMoreReviews(): void {
	// 	this.limit += 5;
	// 	this.getReviews();
	// }
	// /**
	//  * Get the customer last name
	//  * @param lastName: customer last name
	//  * @returns
	//  */
	// public customerLastName(lastName: string) {
	// 	if(this.customerDetail == 'first_name_last_initial'){
	// 		if(lastName){
	// 			return lastName.charAt(0);
	// 		}
	// 	}
	// 	return lastName;
	// }
}
