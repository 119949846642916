import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// Services
import { InitServ, NgOnDestroy, SectionServ, UtilServ } from '../../../../Services';
import { AuthServ } from '../../../../Core/_services'; // Auth Service
//Custom validator
import { CustomValidators } from '../../../../Global/GlobalDefault';

@Component({
	selector: 'bk-customer-email',
	templateUrl: './CustomerEmail.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy],
	// changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerEmailComponent implements OnInit {

	@Input() settings: any;
	@Input() customerForm!: FormGroup;
	@Input() section: any;
	@Input() formLayout: any;
	@Input() prefilledData: any;
	@Input() currentUser: any;
	@Input() isMultiStepForm: boolean = false;
	@Input() pageSett: any;
	@Input() variationId: string = '';
	@Output() loginExistingUser : EventEmitter<any> = new EventEmitter();
	@Output() custmrChng : EventEmitter<any> = new EventEmitter();
	loaderId: string = 'bkng-email';
	extraDescStatus: boolean = false;
	cookieStatus: boolean = false;
	emailExistVar: boolean = false;

	constructor(private customValidators: CustomValidators, private utilServ: UtilServ, public initServ: InitServ, private authServ: AuthServ, private cDRef: ChangeDetectorRef, private secServ: SectionServ) {
	}

	ngOnInit(): void {
		setTimeout(() => {
			if(this.customerGroup.controls['email_id'].value && !this.customerForm.controls['uid'].value){
				this.customerGroup.controls['email_id'].markAsTouched();
				this.emailExistControl(this.customerGroup.controls['email_id']);
			}
		},100);
		// Section status
		if(this.section){
			if(this.section.extra_desc){
				this.extraDescStatus = this.secServ.checkEleStatus(this.pageSett, this.section.extra_desc_id);
			}
			if(this.section.cookie_content){
				this.cookieStatus = this.secServ.checkEleStatus(this.pageSett, this.section.cookie_content_id);
			}
		}
	}

	// convenience getter for easy access to form fields
	get customerGroup(): any{
		return <FormGroup>this.customerForm.controls['customer'];
	}
	/**
	 * On keyup add the password control if not exist
	 */
	public addPwdControl(): void {
		if(!this.customerGroup.controls['password']){
			this.customerGroup.addControl('password', new FormControl('', Validators.required));
			this.customerGroup.controls['password'].markAsUntouched();
		}
	}
	/**
	 * Check the user email exist
	 * @param control: email control
	 */
	public async emailExistControl(control: any, emptyPwd:boolean = true, isChange: boolean = false, formType: string = '') {
		this.emailExistVar = false;
		if(control && control.value && (!control.errors?.required && !control.errors?.pattern)){
			let email: any = await this.customValidators.emailExist(control.value, this.utilServ.userId());
			if(email){
				this.emailExistVar = true;
				if(this.customerGroup.controls['password']){
					if(emptyPwd){
						this.customerGroup.controls['password'].setValue('');
						this.customerGroup.controls['password'].markAsUntouched();
					}
				} else {
					this.addPwdControl();
				}
			}
			control.setErrors(email);
			if(!email && !isChange){
				if(formType){
					this.custmrChng.emit(this.variationId);
				}else{
					this.custmrChng.emit();
				}
			}
			this.cDRef.detectChanges();
		}
	}
	/**
	 * Forgot password
	 */
	public forgotPwd(): void {
		let obj = { email_id: (this.customerGroup.controls['email_id'].value).toLowerCase()};
		this.authServ.passwordResetApi(true, obj, this.loaderId, false);
	}
	/**
	 * Login to existing user
	 */
	public loginUser(): void {
		if(this.customerGroup?.controls['password'].valid){
			this.loginExistingUser.emit();
		} else {
			this.customerGroup?.controls['password'].markAsTouched();
		}
	}

	/**
	 * Refresh the component by parent
	 */
	public refresh(): void{
		this.cDRef.detectChanges();
	}
}
